import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitterSquare, faInstagramSquare, faFacebookSquare, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/Footer.css'; // Import external CSS
import logo from "../assets/Images/Logo1.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          {/* Footer Logo & About Section */}
          <div className="col-lg-3 col-md-6 footer-section">
            <div className="footer-logo">
              <img src={logo} alt="Carrier" />
              <p>
                At Carrier Launchers, we specialize in IT Bench Sales & Recruitment, connecting top IT talent with leading businesses. Our expertise, vast network, and client-focused approach ensure seamless hiring solutions. Whether you're seeking skilled professionals or career opportunities, partner with us for efficient, tailored, and impactful IT staffing solutions.
              </p>
            </div>
            <ul className="social-icons">
              <li><a href="https://www.linkedin.com/company/102868195"><FontAwesomeIcon icon={faLinkedin} /></a></li>
              <li><a href="https://www.twitter.com"><FontAwesomeIcon icon={faTwitterSquare} /></a></li>
              <li><a href="https://www.instagram.com"><FontAwesomeIcon icon={faInstagramSquare} /></a></li>
              <li><a href="https://www.facebook.com/profile.php?id=61557244721671"><FontAwesomeIcon icon={faFacebookSquare} /></a></li>
              <li><a href="https://www.youtube.com"><FontAwesomeIcon icon={faYoutube} /></a></li>
            </ul>
          </div>

          {/* Explore Links */}
          <div className="col-lg-3 col-md-6 footer-section">
            <h5 className="footer-title">Explore Links</h5>
            <ul className="footer-links">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About Us</a></li>
              <li><a href="/">Services</a></li>
              <li><a href="/">Industries</a></li>
              <li><a href="/faq">FAQ</a></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
          </div>

          {/* Services */}
          <div className="col-lg-3 col-md-6 footer-section">
            <h5 className="footer-title">Services</h5>
            <ul className="footer-links">
              <li><a href="/services/it-sourcing">IT Sourcing</a></li>
              <li><a href="/services/it-training">IT Training</a></li>
              <li><a href="/services/consulting-services">Consulting Services</a></li>
              <li><a href="/services/business-process-services">Business Process Services</a></li>
            </ul>
          </div>

          {/* Address */}
          <div className="col-lg-3 col-md-6 footer-section">
            <h5 className="footer-title">Address</h5>
            <ul className="footer-contact">
              <li><FontAwesomeIcon icon={faMapMarkerAlt} /> 1147 Esters Rd, Irving, Texas, USA 75061</li>
              <li><FontAwesomeIcon icon={faEnvelope} />rishi@carrierlaunchers.com</li>
              <li><FontAwesomeIcon icon={faPhone} /> +1 (469) 382-2346</li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} Carrier Launchers. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;