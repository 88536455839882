import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import "../../../assets/css/Banking.css";
import { Container, Row, Col, Card } from "react-bootstrap";

const Banking = ({ title = "Welcome to Carrier Launchers – Your Trusted IT Bench Sales Recruitment Partner for Finance & Banking", description = "Secure and modern banking solutions for everyone!" }) => {
  const content = {
    heading: "Expert IT Bench Sales Recruitment for Finance & Banking",
    companyName: "Your Company Name",
    paragraph1:
      "At Carrier Launchers, we specialize in IT Bench Sales Recruitment Services tailored for the Finance & Banking sector. Our expertise lies in connecting highly skilled IT professionals with top financial institutions, investment banks, fintech firms, and risk & compliance consultancies. We source and place talent proficient in core banking solutions, digital banking, trading platforms, blockchain, cybersecurity, and regulatory compliance. With a strong network of direct clients, vendors, and hiring managers, we ensure rapid placements while meeting industry-specific requirements.",
    subHeading: "Seamless Hiring with Compliance & Industry Expertise",
    paragraph2:
      "Our comprehensive services include candidate sourcing, resume marketing, vendor management, contract negotiation, and onboarding support, all while ensuring compliance with SEC, FINRA, PCI-DSS, GDPR, and other financial industry regulations. We streamline the recruitment process for commercial banks, wealth management firms, payment processors, and risk consulting firms, providing 24/7 support for efficient hiring. If you’re looking for top IT talent in Finance & Banking, [Your Company Name] is your trusted partner. Contact us today to build a skilled and compliant IT workforce for the future of finance!"
  };

  
  const industries = [
    {
      title: "Commercial & Retail Banks",
      description:
        "We support banks with AI-driven solutions, digital banking, cybersecurity, regulatory compliance, and automation. Our IT professionals help optimize banking operations, enhance customer experience, and implement risk management strategies for financial stability."
    },
    {
      title: "Investment & Wealth Management Firms",
      description:
        "Our IT staffing solutions assist investment firms in AI-powered trading, portfolio management, blockchain security, regulatory compliance, and predictive analytics, helping financial institutions optimize investment strategies and ensure secure, data-driven decision-making."
    },
    {
      title: "Fintech & Payment Processing Companies",
      description:
        "We help Fintech companies hire IT experts for payment gateway security, blockchain, AI-driven lending, cloud banking, and fraud prevention, enabling financial firms to innovate and enhance digital payment solutions securely."
    },
    {
      title: "Risk & Compliance Consulting Firms",
      description:
        "Our IT staffing solutions support risk and compliance firms with regulatory platforms, cybersecurity, enterprise risk management, automated auditing, and cloud-based governance to protect sensitive financial data and meet compliance standards efficiently."
    },
    {
      title: "Insurance & Mortgage Lenders",
      description:
        "We provide IT professionals for AI-driven underwriting, claims automation, blockchain-based policy management, mortgage loan platforms, and compliance solutions, ensuring efficiency, security, and seamless digital transformation in the insurance and mortgage sectors."
    }
  ];

   const { pathname } = useLocation(); // Get the current route
  
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when the route changes
    }, [pathname]);

  return (
    <>
    <section>
      <div className="banking-container">
        {/* Background Image Section */}
        <div className="background-image">
          <h2 className="banking-text animated-text">
            {title}
            <span className="underline"></span> {/* Underline Effect */}
          </h2>
        </div>
      </div>
    </section>
    <section>
      <div className="container text-center my-5">
      <h1 className="dynamic-heading">{content.heading}</h1>
      <p className="content-text">{content.paragraph1.replace("[Carrier Launchers]", content.companyName)}</p>
      <h3 className="sub-heading">{content.subHeading}</h3>
      <p className="content-text">{content.paragraph2.replace("[Carrier Launchers]", content.companyName)}</p>
    </div>
    </section>
    <hr/>
    <section>
    <Container className="py-5">
      <div className="text-center header-section">
        <h1>Industries We Serve in Finance & Banking</h1>
        <p>
          At Carrier Launchers, we provide IT Bench Sales Recruitment Services
          for the Finance & Banking sector, helping institutions adopt AI,
          blockchain, and cybersecurity to enhance security, efficiency, and
          regulatory compliance.
        </p>
      </div>
      <Row className="g-4">
        {industries.map((industry, index) => (
          <Col key={index} md={4} sm={6} xs={12}>
            <Card className="custom-card h-100">
              <Card.Body>
                <Card.Title>{industry.title}</Card.Title>
                <Card.Text>{industry.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    </section>
    </>
  );
};

export default Banking;
