import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import "../../../assets/css/Media.css";

const Media = ({ title = "Media & Technology" }) => {
 const { pathname } = useLocation(); // Get the current route

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [pathname]);

  return (
    <>
      {/* Background Section with Title */}
      <section className="unique-media-container">
        <div className="unique-bg-image">
          <h2 className="unique-media-text unique-animated-media-text">
            {title}
            <span className="unique-underline1"></span> {/* Underline Effect */}
          </h2>
        </div>
      </section>

      {/* Recruitment Section */}
      <section className="unique-recruitment-container">
        <div className="unique-content-wrapper">
          <h2 className="unique-main-heading ">
            Specialized IT Bench Sales Recruitment for Media & Technology
          </h2>
          <p className="unique-description">
            At <span className="unique-company-name">Carrier Launchers</span>, we provide 
            <strong>top-tier IT Bench Sales Recruitment Services</strong> for the 
            <span className="unique-highlight">Media & Technology sector</span>, helping 
            businesses connect with highly skilled IT professionals who drive 
            innovation and digital transformation.
          </p>
          <p className="unique-description">
            We specialize in sourcing talent for digital media, entertainment platforms, 
            content management systems (CMS), cloud computing, AI & machine learning, 
            big data analytics, and cybersecurity. Our extensive network of direct clients, 
            technology vendors, and hiring managers ensures seamless placements in 
            fast-paced and evolving digital environments.
          </p>
        </div>

        <div className="unique-content-wrapper">
          <h2 className="unique-main-heading ">
            Streamlined Hiring with Industry-Focused Expertise
          </h2>
          <p className="unique-description">
            Our recruitment solutions include candidate sourcing, resume marketing, 
            vendor management, contract negotiation, and onboarding support, ensuring 
            efficient placements while adhering to industry standards and data security regulations.
          </p>
          <p className="unique-description">
            Whether you need IT professionals for media streaming, OTT platforms, 
            digital advertising, cloud infrastructure, or software development, 
            we provide the right talent to help businesses stay competitive. 
            <span className="unique-company-name">Carrier Launchers</span> is your trusted recruitment partner 
            in the Media & Technology sector. Contact us today to build a cutting-edge IT workforce 
            that drives digital success!
          </p>
        </div>
      </section>
      <section>
      <div className="industry-container">
      {/* Dynamic Heading */}
      <h2 className="industry-dynamic-title">Industries We Serve in Media & Technology</h2>

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <div className="industry-card">
              <h3 className="industry-card-title">
                We provide IT staffing solutions for various industries within the Media & Technology sector, including:
              </h3>
              <ul className="list-group industry-list">
                <li className="list-group-item industry-list-item">
                  <strong>Digital Media & Entertainment:</strong> Streaming services, OTT platforms, gaming, and interactive media.
                </li>
                <li className="list-group-item industry-list-item">
                  <strong>Broadcasting & Publishing:</strong> TV networks, radio stations, news agencies, and digital content platforms.
                </li>
                <li className="list-group-item industry-list-item">
                  <strong>Advertising & Marketing Technology:</strong> AdTech, MarTech, programmatic advertising, and AI-driven marketing solutions.
                </li>
                <li className="list-group-item industry-list-item">
                  <strong>Software & Cloud Computing:</strong> SaaS providers, cloud infrastructure, DevOps, and enterprise software solutions.
                </li>
                <li className="list-group-item industry-list-item">
                  <strong>Cybersecurity & Data Protection:</strong> Security software companies, compliance consulting, and digital identity management.
                </li>
                <li className="list-group-item industry-list-item">
                  <strong>Big Data & AI:</strong> Analytics platforms, artificial intelligence, machine learning, and predictive modeling.
                </li>
                <li className="list-group-item industry-list-item">
                  <strong>E-Learning & EdTech:</strong> Online learning platforms, virtual training solutions, and education technology startups.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
      </section>
    </>
  );
};

export default Media;
