import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import '../../../assets/css/Consulting.css';
import CSC1 from '../../../assets/Images/CSC1.jpg';
import CSC2 from '../../../assets/Images/CSC2.avif';
import CSC3 from '../../../assets/Images/CSC3.avif';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLightbulb,
  faCog,
  faMobileAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

const CustomerSupport = () => {
  const slides = [
    { image: CSC1, text: "Beyond Recruiting—Building Futures" },
    { image: CSC2, text: "We Don’t Just Staff—We Strategize" },
    { image: CSC3, text: "We're Here to Help, Anytime, Anywhere." },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);

  const services = [
    {
      heading: "Proven Expertise and Experience:",
      items: [
        "With years of experience, our team has honed a deep understanding of diverse industries and customer needs. We leverage our expertise to provide tailored software solutions that deliver measurable results.Our proven track record ensures that your business will benefit from industry-leading practices and solutions.",
        
      ],
    },
    {
      heading: "Customer-Centric Approach:",
      items: [
        "We prioritize your needs, listening closely to understand your unique challenges and goals. Our services are designed to provide maximum value by aligning with your business objectives. With dedicated customer success managers, we ensure continuous support to help you achieve long-term success.",
        
      ],
    },
    {
      heading: "Innovative Solutions:",
      items: [
        "Constantly evolving, we integrate the latest technologies to stay ahead of industry trends.Our innovative approach ensures that our solutions are future-proof and adaptable to market changes.We deliver cutting-edge software that drives business growth while maintaining flexibility for emerging needs.",
       
      ],
    },
    {
      heading: "Tailored Solutions for Your Business:",
      items: [
        "We believe in creating bespoke solutions tailored to meet the specific requirements of your business. Our team works closely with you to align our software with your company’s vision and goals.With customized features, we ensure that the solution fits seamlessly into your workflows and drives real results.",
        
      ],
    },
    {
      heading: "Reliable and Scalable Solutions:",
      items: [
        "Our software is designed to be scalable, so it grows with your business as you expand.Whether you're a startup or an enterprise, we provide reliable solutions that adapt to your changing needs.You can rely on us to equip your business with the tools necessary to scale effectively and efficiently.",
        
      ],
    },
    {
      heading: "Comprehensive Support and Training:",
      items: [
        "We provide ongoing training to ensure your team maximizes the value of our software.Our 24/7 support team is always available to address any concerns, ensuring smooth operations.From initial onboarding to advanced feature usage, we guide you every step of the way.",
        
      ],
    },
    {
      heading: "Competitive Pricing and Flexibility:",
      items: [
        "Our pricing is competitive, designed to fit a wide range of budgets, without compromising quality.We offer flexible terms that give you the freedom to adjust services as needed.Whether you’re looking for a short-term solution or a long-term partnership, we adapt to your needs.",
        
      ],
    },
    {
      heading: "Customer Satisfaction Guarantee:",
      items: [
        "Your satisfaction is our top priority, and we are committed to delivering high-quality, value-driven solutions.We work tirelessly to ensure our software meets your expectations and business needs.If you're not satisfied, we’ll go the extra mile to make things right and improve the service.",
        
      ],
    },
    {
      heading: "Industry Recognition and Trust:",
      items: [
        "Trusted by leading businesses across various industries, we have built a strong reputation for excellence.Our clients rely on us for our professionalism, expertise, and commitment to high-quality solutions.We have been consistently recognized for our ability to deliver solutions that create lasting impact.",
        
      ],
    },
    {
      heading: "Proactive and Results-Oriented:",
      items: [
        "We anticipate your needs and act before challenges arise, ensuring smooth and uninterrupted service.Our proactive approach allows us to identify opportunities and solve problems before they become obstacles.Our results-oriented mindset ensures that you achieve the outcomes that matter most, faster and more efficiently.",
        
      ],
    },
  ];

    const { pathname } = useLocation(); // Get the current route
  
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when the route changes
    }, [pathname]);
  

  return (
    <>
      {/* Carousel Section */}
      <section className="carousl-section5">
        <div className="carousl-container5">
          <div
            className="carousel-slide6 zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="txt-overlay5">
              <p className="carousel-txt5">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <Container className="it-text-center it-mt-5">
      {/* Header Section */}
      <h2 className="it-main-title">IT Solution Provider & Consulting Company</h2>
      <p className="it-sub-title">
        We Provide Cost-Effective Information Technology Solutions And Services – 
        Software And Applications Development, Mobile App Development, Data Warehousing, 
        Storage Services, IT-Resources, Testing & QA, And Software Development – To Discerning Clients Globally.
      </p>

      {/* Features Section */}
      <Row className="it-mt-5">
        <Col md={6} className="it-feature-box">
          <FontAwesomeIcon icon={faLightbulb} className="it-feature-icon" />
          <h4 className="it-feature-title">CREATIVE WORK</h4>
          <p className="it-feature-description">
            We leverage our design creativity with passion for art in every project.
          </p>
        </Col>
        <Col md={6} className="it-feature-box">
          <FontAwesomeIcon icon={faCog} className="it-feature-icon" />
          <h4 className="it-feature-title">GREAT SUPPORT</h4>
          <p className="it-feature-description">
            We are available round the clock to assist customers in the best possible way.
          </p>
        </Col>
      </Row>
      <Row className="it-mt-4">
        <Col md={6} className="it-feature-box">
          <FontAwesomeIcon icon={faMobileAlt} className="it-feature-icon" />
          <h4 className="it-feature-title">FULLY RESPONSIVE</h4>
          <p className="it-feature-description">
            Almost every new client these days wants a mobile version of their website. 
            So we make it easy to access your website from any device.
          </p>
        </Col>
        <Col md={6} className="it-feature-box">
          <FontAwesomeIcon icon={faUsers} className="it-feature-icon" />
          <h4 className="it-feature-title">DEDICATED TEAM</h4>
          <p className="it-feature-description">
            When you need support and assistance during the middle of the night, 
            our team is available to answer your calls or emails.
          </p>
        </Col>
      </Row>
    </Container>
      <hr/>
      <section className="custom-section custom-bg-f2f2f2 custom-py-5">
        <div className="custom-background-image3">
          <div className="custom-content2">
            <h1>Consulting Services</h1>
            <p>
            At Carrier Launchers, we provide specialized IT Bench Sales Recruitment Services for the Consulting Services industry, helping firms find top IT talent to support digital transformation and business growth. Our recruitment expertise covers enterprise solutions, cloud computing, AI-driven analytics, cybersecurity, automation, and IT strategy consulting, ensuring seamless hiring for a rapidly evolving industry. With a vast network of direct clients, vendors, and hiring managers, we accelerate placements and optimize workforce management for consulting firms.
            </p>
          </div>
        </div>
        <div className="custom-cont">
          <div className="custom-row">
          </div>
          <div className="custom-row">
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">IT Staffing & Talent Acquisition</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  <strong>IT Staffing & Talent Acquisition,</strong> We provide full-cycle IT recruitment, including contract, contract-to-hire, and permanent placements, ensuring businesses hire top-tier talent. Our bench sales and consultant marketing services connect skilled professionals with the right opportunities through proactive networking and vendor management. We specialize in executive and niche IT talent search, recruiting cloud architects, cybersecurity experts, and ERP consultants. Our offshore and nearshore staffing solutions help businesses optimize costs while maintaining productivity through global talent access. Additionally, we offer immigration and compliance support, assisting with H-1B visa processing, work authorization transfers, and regulatory adherence to ensure seamless onboarding and legal compliance.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title"> Talent Marketing & Consultant Placement</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  <strong> Talent Marketing & Consultant Placement,</strong> We specialize in marketing IT consultants (bench candidates) to clients and vendors, ensuring quick placements. Our approach includes building vendor networks, conducting market research, and proactive outreach. By matching top talent with the right opportunities, we streamline hiring, enhance consultant utilization, and maximize placement success in the competitive IT market.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Strategic Vendor & Client Relationship Management</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  <strong>Strategic Vendor & Client Relationship Management,</strong> We establish and nurture partnerships with Tier-1 vendors, direct clients, and system integrators to optimize the hiring process. Our expertise includes contract negotiations, compliance management, and strategic engagement, ensuring consistent job opportunities for consultants. By strengthening client-vendor relationships, we drive higher placement success rates, sustained business growth, and market competitiveness.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Contract Negotiation & Compliance Management</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  <strong>Contract Negotiation & Compliance Management,</strong> We handle contract structuring, rate negotiations, and compliance management to ensure fair and profitable agreements for both consultants and employers. Our services include legal documentation, background verification, tax compliance, and work authorization processing, ensuring all placements adhere to regulatory standards and industry best practices.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Resume Optimization & Career Branding</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  <strong>Resume Optimization & Career Branding,</strong> We assist consultants in crafting compelling resumes, LinkedIn profiles, and professional portfolios that highlight their technical expertise and experience. Our resume marketing strategy focuses on industry-specific keyword optimization, formatting improvements, and skills enhancement, making candidates more attractive to hiring managers and increasing their chances of securing high-paying roles.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Immigration & Work Authorization Support</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  We provide comprehensive support for H-1B, OPT, CPT, GC, and other work authorization processes, ensuring smooth employment transitions for consultants. Our team offers guidance on visa transfers, extensions, and legal compliance, helping IT professionals maintain valid work status while securing long-term career opportunities in the U.S.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            {/* Repeat this block for other sections */}
          </div>
        </div>
      </section>
      <hr/>
      <section>
      <div className="content-container">
        <h2 className="main-header">Why Choose Us?</h2>
        {services.map((service, index) => (
          <div key={index} className="service-section">
            <h3 className="sub-header">{service.heading}</h3>
            <ul className="content-list">
              {service.items.map((item, itemIndex) => (
               <p key={itemIndex} className="content-text">{item}</p>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
    </>
  );
};

export default CustomerSupport;
