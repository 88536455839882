import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import "../../../assets/css/Public.css";
import { Container, Row, Col, Card } from 'react-bootstrap';
const Public = ({ title = "Connecting Public Services Businesses with Expert IT Professionals" }) => {

  const industries = [
    {
      title: "Government & Municipal Services",
      content: "Governments at all levels—federal, state, and local—are modernizing their IT infrastructure to improve efficiency and public service delivery.",
      keyPoints: [
        "Cloud-Based Government Portals – Enabling digital access to public services.",
        "Cybersecurity & Data Protection – Securing sensitive citizen data and infrastructure.",
        "AI & Automation for Public Administration – Streamlining workflows and reducing manual processes.",
        "Smart City Technologies & IoT – Enhancing urban planning, traffic management, and sustainability."
      ]
    },
    {
      title: "Law Enforcement & Public Safety",
      content: "Public safety agencies require secure and high-performing IT solutions to enhance efficiency and emergency response.",
      keyPoints: [
        "AI & Predictive Analytics for Crime Prevention – Improving law enforcement decision-making.",
        "Cloud-Based Case Management Systems – Enhancing evidence tracking and investigation processes.",
        "Real-Time Surveillance & IoT Security Solutions – Strengthening public safety infrastructure.",
        "Cybercrime & Digital Forensics – Supporting data analysis in criminal investigations."
      ]
    },
    {
      title: "Healthcare & Social Services",
      content: "Public healthcare and social service agencies need modern IT systems to enhance patient care and welfare programs.",
      keyPoints: [
        "Electronic Health Records (EHR) & Telehealth Solutions – Improving patient care and accessibility.",
        "AI & Data Analytics for Public Health – Supporting research and disease control initiatives.",
        "Digital Payment & Welfare Distribution Systems – Ensuring efficient and secure transactions.",
        "Cloud & Blockchain for Health Data Security – Enhancing interoperability and regulatory compliance."
      ]
    },
    {
      title: "Education & Research Institutions",
      content: "The education sector is evolving with EdTech innovations, requiring IT professionals skilled in:",
      keyPoints: [
        "Learning Management Systems (LMS) & Virtual Classrooms – Enabling online education.",
        "AI-Powered Personalized Learning Solutions – Enhancing student engagement.",
        "Cybersecurity for Student & Faculty Data – Protecting sensitive educational records.",
        "Cloud Computing & Data Analytics for Research – Supporting academic institutions in digital transformation."
      ]
    },
    {
      title: "Transportation & Infrastructure",
      content: "Public transportation and infrastructure projects rely on IT-driven solutions to improve efficiency and safety.",
      keyPoints: [
        "Smart Traffic Management & IoT-Based Systems – Enhancing urban mobility.",
        "AI & Predictive Maintenance for Public Transport – Reducing downtime and improving efficiency.",
        "Cybersecurity for Critical Infrastructure – Protecting essential public services.",
        "Cloud & Data Analytics for Transportation Planning – Optimizing routes and reducing congestion."
      ]
    }
  ];

   const { pathname } = useLocation(); // Get the current route
  
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when the route changes
    }, [pathname]);

  return (
    <>
    <section className="public-container">
      <div className="public-bg-image">
        <h2 className="public-text animated-public-text">
          {title}
          <span className="public-underline"></span> {/* Underline Effect */}
        </h2>
      </div>
    </section>
    <section>
    <div className="public-container public-my-5">
      <div className="public-row">
        <div className="public-col-md-12">
          <h1 className="public-display-4 public-text-center public-mb-4">
            Expert IT Bench Sales Recruitment for Public Services
          </h1>
          <p className="public-lead public-text-center">
            At <strong>Carrier Launchers</strong>, we provide specialized IT Bench Sales Recruitment Services for the Public Services sector, helping government agencies, public institutions, and non-profit organizations find highly skilled IT professionals to drive digital transformation. Our expertise includes cloud computing, cybersecurity, AI-driven automation, data analytics, ERP solutions, and smart city technologies. With a strong network of direct clients, vendors, and hiring managers, we ensure seamless placements and quick hiring processes to support public service initiatives efficiently.
          </p>
        </div>
      </div>

      <div className="public-row public-mt-5">
        <div className="public-col-md-12">
          <h2 className="public-text-center public-mb-4">
            Tailored IT Staffing Solutions for Government & Public Sector
          </h2>
          <p className="public-text-center">
            We offer comprehensive staffing solutions, including candidate sourcing, resume marketing, vendor management, contract negotiation, and onboarding support, ensuring that public service organizations have access to top-tier IT talent. Our recruitment services cover:
          </p>
          <ul className="public-list-group public-list-group-flush">
            <li className="public-list-group-item">E-Government & Cloud-Based Portals – Enhancing digital service accessibility for citizens.</li>
            <li className="public-list-group-item">AI & Data Analytics for Public Administration – Optimizing governance and decision-making.</li>
            <li className="public-list-group-item">Cybersecurity & Data Protection – Safeguarding sensitive public data against cyber threats.</li>
            <li className="public-list-group-item">IoT & Smart City Technologies – Supporting urban infrastructure development and sustainability.</li>
          </ul>
        </div>
      </div>
    </div>
    </section>
    <section>
    <div>
      {/* Header Section with Background Image */}
      <div className="public-header-section">
        <Container>
          <Row className="public-justify-content-center">
            <Col md={8} className="public-text-center">
              <h1>Industries We Serve: Public Services</h1>
              <p>
                At Carrier Launchers, we specialize in IT Bench Sales Recruitment Services for the Public Services sector, helping government agencies, public institutions, and non-profit organizations adopt digital transformation. With advancements in cloud computing, AI, cybersecurity, data analytics, and automation, public service organizations require top IT professionals to enhance operational efficiency, data security, and citizen services.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Cards Section */}
      <Container className="public-card-container">
        <Row>
          {industries.map((industry, index) => (
            <Col key={index} md={4} className="public-mb-4">
              <Card className="public-h-100">
                <Card.Body>
                  <Card.Title className="public-card-title">{industry.title}</Card.Title>
                  <Card.Text className="public-card-text">{industry.content}</Card.Text>
                  <ul className="public-ul">
                    {industry.keyPoints.map((point, i) => (
                      <li key={i} className="public-li">{point}</li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
    </section>
    </>
  );
}

export default Public
