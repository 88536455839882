import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../assets/css/Contact.css";
import CT from "../../assets/Images/contact-png.png";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaMobileAlt,
  FaEnvelopeOpen,
  FaMapMarkerAlt,
  FaClock,
} from "react-icons/fa";
import emailjs from "emailjs-com"; // Import EmailJS

const Contact = () => {
  // State to handle form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  // Handle form field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Prepare the data to send to EmailJS
    const emailData = {
      fullName: `${formData.firstName} ${formData.lastName}`.trim(), // Combining first and last name
      regarding: formData.regarding,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    // Send the form data to EmailJS
    emailjs
      .send(
        "service_ofyikql", // Replace with your EmailJS service ID
        "template_1de7so8", // Replace with your EmailJS template ID
        emailData, // Directly pass the data
        "jtpwxCcbI-QI6Izqa" // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          alert("Thank you! We've received your details and will be in touch soon!");
          console.log(result.text); // Log result for debugging
        },
        (error) => {
          alert("Failed to send message. Please try again!");
          console.error(error.text); // Log error for debugging
        }
      );
  };

  const { pathname } = useLocation(); // Get the current route

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [pathname]);

  return (
    <section className="contact-section">
      <div className="contact-bg">
        <h2>Contact Us</h2>
        <div className="line">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div className="contact-body">
      <div className="contact-info">
        {/* Phone Numbers */}
        <div>
          <span>
            <FaMobileAlt />
          </span>
          <span>Phone No. (USA)</span>
          <span className="text">+1 (469) 382-2346</span>
        </div>

        {/* Email */}
        <div>
          <span>
            <FaEnvelopeOpen />
          </span>
          <span>E-mail</span>
          <span className="text">rishi@carrierlaunchers.com</span>
        </div>

        {/* Headquarters (USA) */}
        <div>
          <span>
            <FaMapMarkerAlt />
          </span>
          <span>Headquarters (USA)</span>
          <span className="text">1147 Esters Rd, Irving, Texas, USA 75061</span>
        </div>

        <div>
          <span>
            <FaClock />
          </span>
          <span>Office Hours (USA)</span>
          <span className="text">Monday - Friday (9:00 AM to 5:00 PM CST)</span>
        </div>

        {/* Branch Office (India) */}

        <div>
          <span>
            <FaMobileAlt />
          </span>
          <span>Phone No. (India)</span>
          <span className="text">+91 70069 36194</span>
        </div>

        <div>
          <span>
            <FaEnvelopeOpen />
          </span>
          <span>E-mail</span>
          <span className="text">sudesh@carrierlaunchers.com</span>
        </div>

        <div>
          <span>
            <FaMapMarkerAlt />
          </span>
          <span>Branch Office (India)</span>
          <span className="text">Sector 63 Noida, Gautam Buddh Nagar, New Delhi, India 201301</span>
        </div>

        <div>
          <span>
            <FaClock />
          </span>
          <span>Office Hours (India)</span>
          <span className="text">Monday - Friday (7:00 PM to 4:00 AM IST)</span>
        </div>
      </div>
      </div>

      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="email"
              className="form-control"
              placeholder="E-mail"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              type="text"
              className="form-control"
              placeholder="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <select
            className="form-control"
            name="regarding"
            value={formData.regarding}
            onChange={handleChange}
          >
            <option value="">How We Support You</option>
            <option value="IT Consulting">IT Sourcing</option>
            <option value="Cloud Solutions">Consulting Services</option>
            <option value="Cybersecurity">IT Training</option>
            <option value="Software Development">Business Process Services</option>
          </select>
          <textarea
            rows="5"
            placeholder="Tell us about yourself"
            className="form-control"
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
          <input type="submit" className="send-btn" value="Send Message" />
        </form>

        <div>
          <img src={CT} alt="Contact" />
        </div>
      </div>

      <div className="map" style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
  {/* USA Location */}
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3352.7369823608133!2d-97.01440807570194!3d32.8257332814971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e816350a567cf%3A0xdd55dd6daefd0492!2s1147%20Esters%20Rd%2C%20Irving%2C%20TX%2075061%2C%20USA!5e0!3m2!1sen!2sin!4v1731190750070!5m2!1sen!2sin"
    width="50%"
    height="450"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="lazy"
    title="USA Location"
  ></iframe>

  {/* India Location */}
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1758.0215120182913!2d77.3745798880546!3d28.61393949264998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce54f824fa1eb%3A0x8f9e79c56e7d44b8!2sSector%2063%2C%20Noida%2C%20Uttar%20Pradesh%20201301%2C%20India!5e0!3m2!1sen!2sin!4v1731190990082!5m2!1sen!2sin"
    width="50%"
    height="450"
    style={{ border: 0 }}
    allowFullScreen=""
    loading="lazy"
    title="India Location"
  ></iframe>
</div>

      <div className="contact-footer">
        <h3>Follow Us</h3>
        <div className="social-links">
          <a href="https://www.facebook.com/profile.php?id=61557244721671" aria-label="Facebook" className="social-link">
            <FaFacebookF />
          </a>
          <a href="https://www.twitter.com" aria-label="Twitter" className="social-link">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com" aria-label="Instagram" className="social-link">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com/company/102868195" aria-label="LinkedIn" className="social-link">
            <FaLinkedin />
          </a>
          <a href="https://www.youtube.com" aria-label="YouTube" className="social-link">
            <FaYoutube />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
