import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import '../../../assets/css/BPO.css';
import BP1 from '../../../assets/Images/BP1.avif'
import BP2 from '../../../assets/Images/BP2.jpg';
import BP3 from '../../../assets/Images/BP3.avif';
import BP4 from '../../../assets/Images/BP4.avif';
import { useNavigate } from 'react-router-dom';

const BPO = () => {
  const slides = [
    { image: BP1, text: "Innovative BPO Services, Powered by Cutting-Edge Technology" },
    { image: BP2, text: "Next-Gen BPO Solutions for a Connected World"},
    { image: BP3, text: "Your Business, Our BPO Expertise—Growing Together" },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);
  const services = [
    {
      heading: "Proven Expertise and Experience:",
      items: [
        " With years of experience across various industries, we bring deep knowledge and tailored solutions to optimize your business operations. Our BPO team ensures high-quality results, making us a trusted partner in driving operational efficiency."
      ],
    },
    {
      heading: "Cost-Effective Solutions: ",
      items: [
        "We offer competitive pricing without compromising on quality. By outsourcing to us, you gain access to advanced resources, technology, and skilled personnel at a fraction of the cost of maintaining an in-house team.",
        
      ],
    },
    {
      heading: "Scalability and Flexibility:",
      items: [
        "As your business grows, our BPO services can scale with you. Whether you need more support during peak times or seek long-term outsourcing solutions, we are equipped to adapt to your evolving requirements.",
        
      ],
    },
    {
      heading: "Advanced Technology:",
      items: [
        "We utilize cutting-edge technologies to ensure seamless service delivery. From automation to AI-driven insights, we integrate the latest tools to help your business stay ahead of the competition.",
        
      ],
    },
    {
      heading: "Focus on Core Competencies:",
      items: [
        "By partnering with us, your business can focus on strategic initiatives while we handle time-consuming administrative tasks. This allows you to allocate more resources to driving innovation and achieving business growth.",
        
      ],
    },
    {
      heading: "High-Quality Service: :",
      items: [
        "Our team of experts is dedicated to providing exceptional service across all BPO functions, ensuring that every interaction with your business is professional, responsive, and customer-centric.",
        
      ],
    },
    {
      heading: "Global Reach:",
      items: [
        "Our services are designed to support clients across different regions and time zones, enabling your business to operate efficiently on a global scale with around-the-clock support.",
        
      ],
    },
    {
      heading: "Data Security and Compliance:",
      items: [
        "We prioritize data security and comply with industry standards to ensure your information is handled securely. Our BPO services are designed to meet the highest levels of regulatory compliance.",
        
      ],
    },
  ];

  const navigate = useNavigate();

  const handleBookNowClick = () => {
    navigate('/contact');
  };

    const { pathname } = useLocation(); // Get the current route
  
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when the route changes
    }, [pathname]);
  

  return (
    <>
       {/* Carousel Section */}
       <section className="carousl-section6">
        <div className="carousl-container6">
          <div
            className="carousel-slide7 zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="txt-overlay6">
              <p className="carousel-txt6">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="vision7-section">
          <div className="containers">
            <div className="vision7-content">
              <h2 className="vision7-heading">Our Vision</h2>
              <p className="vision7-description">
              At Carrier Launchers, our vision is to be the premier provider of innovative Business Process Outsourcing (BPO) solutions 
              that help businesses unlock their full potential. We aim to transform traditional outsourcing by integrating advanced technology,
               automation, and data-driven strategies into every process. Our goal is to deliver efficient, scalable, and cost-effective BPO 
               services that drive operational excellence and enhance customer experiences.<br/>
               We are committed to building long-lasting relationships with our clients through transparency, trust, and a deep understanding 
               of their unique needs. By focusing on continuous improvement, we ensure that our solutions evolve with the changing demands of the market.
               Our vision is to empower businesses to focus on their core competencies while we streamline their non-core operations, delivering measurable
              results and accelerating their growth. Through innovation, expertise, and a customer-first approach, we will reshape the future of BPO for a smarter, more connected world.
              </p>
              <a href="/" className="readmore7">
                    read more --
                  </a>
            </div>
            <div className="vision7-image">
              <img src={BP4} alt="Vission7" />
            </div>
          </div>
        </div>
      </section>
      <hr/>
      <section className="cust-section cust-bg-f2f2f2 cust-py-5">
        <div className="cust-background-image3">
          <div className="cust-content2">
            <h1>End-to-End BPO Services to Streamline Your Business</h1>
            <p>
            The primary aim of offering Business Process Outsourcing (BPO) services by Carrier Launchers is to help organizations optimize their operational efficiency, reduce costs, and enhance business performance by leveraging external expertise in specialized business functions. By outsourcing non-core tasks, companies can focus on their core competencies, improve service quality, and gain a competitive edge in the marketplace.
            </p>
          </div>
        </div>
        <div className="cust-cont">
          <div className="cust-row">
          </div>
          <div className="cust-row">
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Customer Support Services</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Phone Support</strong> services offer both inbound and outbound call handling to address <strong>customer queries</strong>, resolve complaints, and gather valuable <strong>feedback</strong>. With our <strong>Email Support</strong>, we efficiently manage <strong>customer emails</strong>, responding to inquiries and providing <strong>solutions</strong> to issues. Our <strong>Chat Support</strong> provides <strong>real-time chat assistance</strong> to ensure customers get quick resolutions for their problems. Additionally, we offer <strong>Social Media Support</strong>, where we monitor and respond to <strong>customer inquiries</strong> across various social media platforms, ensuring prompt and effective communication. Our <strong>Helpdesk Services</strong> provide expert <strong>technical support</strong> and troubleshooting across multiple channels, guaranteeing a seamless customer experience and timely issue resolution.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Data Entry and Data Processing</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Data Entry</strong> services include both manual and automated entry of data into systems, such as <strong>forms</strong>, <strong>surveys</strong>, and <strong>records</strong>, ensuring accuracy and efficiency. We also specialize in <strong>Data Conversion</strong>, converting data from one format to another, such as transforming <strong>paper</strong> documents into <strong>digital</strong> formats. Our <strong>Data Mining</strong> services help extract valuable <strong>data</strong> from large datasets to provide actionable business <strong>insights</strong>. Through <strong>Data Management</strong>, we organize and maintain large volumes of <strong>data</strong> for easy access, improving efficiency and usability. Additionally, our <strong>Data Analytics</strong> services allow businesses to analyze data, generate detailed <strong>reports</strong>, and produce accurate <strong>insights</strong> and <strong>forecasts</strong> to inform decision-making.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Human Resources Outsourcing</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Recruitment Services</strong> handle the entire recruitment process, from sourcing candidates to seamless <strong>onboarding</strong>, ensuring the right fit for your organization. We offer reliable <strong>Payroll Processing</strong>, handling the calculation and distribution of employee salaries while ensuring strict <strong>tax compliance</strong>. Our <strong>Benefits Administration</strong> services manage essential employee benefits, including <strong>health insurance</strong>, leave, and <strong>retirement plans</strong>, keeping your team satisfied and secure. With <strong>Employee Records Management</strong>, we maintain up-to-date and accurate records of employee performance, compliance, and other critical data. Additionally, our <strong>Training & Development</strong> programs provide employees with the necessary skills, <strong>certification</strong>, and ongoing professional development to foster growth and success within your organization.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Finance and Accounting Outsourcing</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Bookkeeping</strong> services manage day-to-day financial records and transactions, ensuring accurate tracking of your financial activities. We provide comprehensive <strong>Invoicing & Billing</strong> services, handling customer invoices, billing schedules, and timely <strong>payment reminders</strong> to maintain smooth cash flow. Our <strong>Tax Preparation</strong> services ensure compliance by handling tax returns, calculations, and adherence to local regulations, reducing the risk of penalties. We offer detailed <strong>Financial Reporting</strong>, generating essential financial statements and reports that inform management and stakeholders about the company’s financial health. Our <strong>Accounts Payable & Receivable</strong> services streamline the management of incoming and outgoing payments, ensuring timely <strong>vendor payments</strong> and efficient customer collections to maintain financial stability.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title"> IT Helpdesk and Support Services</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Technical Support</strong> services provide expert assistance to both customers and employees, addressing <strong>software</strong> and <strong>hardware issues</strong> quickly and efficiently. Through <strong>System Monitoring</strong>, we proactively monitor systems to identify potential issues before they disrupt operations, ensuring uninterrupted service. Our <strong>Troubleshooting</strong> services resolve <strong>IT-related issues</strong>, including <strong>software bugs</strong> and <strong>hardware malfunctions</strong>, minimizing downtime. We offer comprehensive <strong>Network Management</strong>, ensuring that your network infrastructure is <strong>secure</strong>, <strong>stable</strong>, and operating at peak efficiency. Additionally, our <strong>Cloud Support</strong> services assist with <strong>cloud infrastructure</strong> management, optimization, and troubleshooting, ensuring seamless performance and scalability for your business needs.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Sales and Marketing Outsourcing</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Lead Generation</strong> services help identify and qualify potential leads, providing your sales team with high-quality prospects to convert into customers. Through <strong>Customer Acquisition</strong>, we manage campaigns and activities that attract new customers, ensuring business growth and market expansion. Our <strong>Digital Marketing</strong> services include <strong>SEO</strong> (Search Engine Optimization), <strong>SEM</strong> (Search Engine Marketing), and social media marketing strategies to boost your online presence and drive traffic to your business. With <strong>Email Marketing</strong>, we create and manage targeted campaigns that engage customers, build relationships, and drive sales conversions. Our <strong>Market Research</strong> services involve gathering and analyzing valuable data on <strong>market trends</strong>, competitors, and customer preferences, providing actionable insights to inform your business strategy.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Knowledge Process Outsourcing (KPO)</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Business Research</strong> services provide in-depth analysis on industries, competitors, and market opportunities, helping businesses stay informed and ahead of trends. With <strong>Legal Process Outsourcing (LPO)</strong>, we assist with a variety of legal tasks, including <strong>contract management</strong>, <strong>legal research</strong>, and <strong>document review</strong>, ensuring compliance and efficiency in your legal operations. Our <strong>Content Development</strong> team specializes in creating high-quality content for <strong>blogs</strong>, websites, <strong>articles</strong>, and <strong>marketing materials</strong>, helping your brand engage and resonate with audiences. Through <strong>Data Analysis and Reporting</strong>, we analyze complex data and generate actionable insights, presenting them in clear, strategic reports to guide your decision-making process and drive business growth.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Supply Chain and Logistics Outsourcing</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Order Processing</strong> services ensure the efficient management and processing of customer orders, guaranteeing timely delivery and customer satisfaction. With <strong>Inventory Management</strong>, we monitor stock levels, track inventory, and manage replenishment to prevent shortages or <strong>overstocking</strong>, optimizing supply chain efficiency. Our <strong>Procurement Services</strong> handle the sourcing and purchasing of products or services on behalf of clients, ensuring cost-effectiveness and quality. Through our <strong>Logistics and Distribution</strong> services, we coordinate the shipment and distribution of goods, ensuring smooth and timely deliveries while minimizing logistical challenges.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">IT Development and Software Support</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Custom Software Development</strong> services focus on creating and maintaining software applications that are precisely tailored to meet your business's unique needs and objectives. With our <strong>Application Maintenance</strong> services, we provide continuous support, updates, and improvements to ensure your software stays relevant and efficient. Our <strong>Mobile App Development</strong> team specializes in designing, developing, and maintaining mobile applications for both <strong>Android</strong> and <strong>iOS platforms</strong>, ensuring a seamless experience for users. We also offer expert <strong>Web Development</strong> services, creating responsive, user-friendly websites and web applications that align with your business goals and engage customers effectively.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Document Management and Processing</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Document Scanning and Digitization</strong> services convert paper documents into <strong>digital formats</strong>, enabling easy storage, access, and retrieval. With <strong>Document Indexing</strong>, we organize and categorize documents to enhance <strong>retrieval</strong> efficiency, ensuring that important information is always at your fingertips. Our <strong>Document Archiving</strong> services provide secure, long-term digital storage solutions for critical documents, safeguarding them from loss or damage while ensuring compliance and easy access when needed.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Healthcare BPO Services</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Medical Billing & Coding</strong> services ensure accurate and efficient billing processes, handling medical claims and insurance coding in compliance with industry standards. Through <strong>Medical Transcription</strong>, we transcribe medical records from voice notes or dictations, ensuring accuracy and confidentiality for healthcare providers. Our <strong>Claims Processing</strong> team handles and processes insurance claims, streamlining reimbursement for healthcare providers and reducing administrative burdens. We also offer comprehensive <strong>Patient Support</strong> services, assisting with appointment scheduling, follow-ups, and administrative tasks to enhance patient experience and ensure seamless healthcare operations.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="cust-col-md-4 cust-mt-3">
              <div className="cust-cd">
                <div className="cust-cd-body cust-text-center">
                  <h5 className="cust-cd-title">Legal Process Outsourcing (LPO)</h5>
                  <div
                    className="cust-underline cust-d-block cust-mx-auto cust-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="cust-cd-text">
                  Our <strong>Document Review and Management</strong> services provide thorough review and management of legal documents, ensuring accuracy, compliance, and efficient organization for law firms and corporate clients. With our <strong>Legal Research</strong> services, we conduct in-depth research to support case development, providing valuable insights and references for legal proceedings. Our <strong>Contract Management</strong> team specializes in drafting, reviewing, and managing contracts and agreements, ensuring that all terms are clear, legally sound, and in the best interest of our clients.
                  </p>
                  <a href="/" className="cust-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            </div>
        </div>
      </section>
      <hr/>
      <section>
      <div className="content-container">
        <h2 className="main-header">Why Choose Us for BPO?</h2>
        {services.map((service, index) => (
          <div key={index} className="service-section">
            <h3 className="sub-header">{service.heading}</h3>
            <ul className="content-list">
              {service.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
    <hr/>
    <section className="appointmentb">
      <div className="containerb">
        <div className="factb">
          <h2 className="fact-headingb">How Can We Assist You?</h2>
          <p className="fact-descriptionb">
          We value your feedback and are here to assist with any inquiries or support you may need. Please feel free to reach out to us
          </p>
          <button onClick={handleBookNowClick} className="btn-appointmentb">Book Now</button> {/* Action Button */}
        </div>
      </div>
    </section>

    </>
  )
}


export default BPO
