import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
//import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import "../../../assets/css/Issurance.css";

const Issurance = ({ title = "Connecting Issurance Businesses with Expert IT Professionals" }) => {

    const whyChooseUs = [
        {
          icon: "🔹",
          title: "Industry-Specific Expertise",
          content: "Deep understanding of insurance IT needs and regulatory requirements.",
        },
        {
          icon: "🔹",
          title: "Proven Track Record",
          content: "Successful IT placements and projects with leading insurance firms and insurtech companies.",
        },
        {
          icon: "🔹",
          title: "Scalable Solutions",
          content: "Customized strategies to support business growth and digital transformation.",
        },
        {
          icon: "🔹",
          title: "End-to-End Support",
          content: "From consulting and staffing to project execution and compliance management.",
        },
      ];

      const insuranceServices = [
        {
          icon: "✅",
          title: "Policy Administration System Modernization",
          content: "Upgrading legacy platforms for better scalability, automation, and compliance.",
        },
        {
          icon: "✅",
          title: "Claims Processing Automation",
          content: "Implementing AI-powered fraud detection, risk assessment, and faster claims settlement.",
        },
        {
          icon: "✅",
          title: "Data Analytics & Business Intelligence",
          content: "Leveraging big data, predictive modeling, and machine learning for better decision-making.",
        },
        {
          icon: "✅",
          title: "Cybersecurity & Compliance",
          content: "Ensuring data protection and adherence to GDPR, HIPAA, and other regulatory requirements.",
        },
        {
          icon: "✅",
          title: "Cloud-Based Solutions",
          content: "Migrating and managing cloud infrastructure for improved efficiency and scalability.",
        },
        {
          icon: "✅",
          title: "CRM & Customer Experience Enhancement",
          content: "Developing seamless digital platforms for policyholders and agents.",
        },
      ];
      
      const staffingServices = [
        {
          icon: "✔",
          title: "Experienced IT Professionals",
          content: "Access to skilled developers, analysts, cybersecurity experts, and cloud specialists.",
        },
        {
          icon: "✔",
          title: "Quick Turnaround Time",
          content: "Fast resume marketing, screening, and placement processes.",
        },
        {
          icon: "✔",
          title: "Flexible Hiring Models",
          content: "Contract, contract-to-hire, and full-time placements based on business needs.",
        },
        {
          icon: "✔",
          title: "Vendor & Client Management",
          content: "Strong relationships with direct clients, Tier-1 vendors, and hiring managers.",
        },
      ];

       const { pathname } = useLocation(); // Get the current route
      
        useEffect(() => {
          window.scrollTo(0, 0); // Scroll to the top when the route changes
        }, [pathname]);

  return (
    <>
    <section>
    <div>
      {/* Issue Container Section */}
      <section className="issue-container">
        <div className="issue-bg-image">
          <h2 className="issue-text animated-issue-text">
            {title}
            <span className="issue-underline"></span> {/* Underline Effect */}
          </h2>
        </div>
      </section>

      {/* Header Section */}
      <section className="insurance-header">
        <div className="insurance-header-content">
          <h1>Comprehensive Insurance IT Solutions & Staffing Services</h1>
          <p>
            At Carrier Launchers, we specialize in providing IT consulting, staffing, and digital transformation services tailored for the insurance industry. Our expertise spans across property & casualty, life & annuities, health insurance, and reinsurance sectors, helping organizations modernize legacy systems, enhance operational efficiency, and improve customer experiences through cutting-edge technology.
          </p>
        </div>
      </section>

      {/* Customized IT Solutions Section */}
      <section className="insurance-services">
        <h2>Customized IT Solutions for the Insurance Sector</h2>
        <p>
          We offer tailored IT services and staffing solutions to address the evolving needs of insurance companies, brokers, and insurtech firms. Our skilled professionals specialize in:
        </p>
        <div className="services-grid">
          {insuranceServices.map((service, index) => (
            <div key={index} className="service-item">
              <span className="service-icon">{service.icon}</span>
              <h3>{service.title}</h3>
              <p>{service.content}</p>
            </div>
          ))}
        </div>
      </section>

      {/* IT Staffing & Recruitment Section */}
      <section className="insurance-staffing">
        <h2>IT Staffing & Recruitment for Insurance Companies</h2>
        <p>
          Finding the right talent is crucial for technology-driven insurance operations. We provide:
        </p>
        <div className="staffing-grid">
          {staffingServices.map((service, index) => (
            <div key={index} className="staffing-item">
              <span className="staffing-icon">{service.icon}</span>
              <h3>{service.title}</h3>
              <p>{service.content}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
    </section>
      {/* Why Choose Us Section in Card Form */}
      <section className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <div className="cards-grid">
          {whyChooseUs.map((item, index) => (
            <div key={index} className="why-card">
              <span className="icon">{item.icon}</span>
              <h3>{item.title}</h3>
              <p>{item.content}</p>
            </div>
          ))}
        </div>
    </section>
    </>
  )
}

export default Issurance
