import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import '../../../assets/css/Training.css';
import IT1 from '../../../assets/Images/IT1.jpg'
import IT2 from '../../../assets/Images/IT2.jpg';
import IT3 from '../../../assets/Images/IT3.jpg';

const Training = () => {
  const slides = [
    { image: IT1, text: "Unlock Your Potential with Expert IT Training." },
    { image: IT2, text: "From Learning to Earning—We Guide Your IT Journey." },
    { image: IT3, text: "Stay Ahead with Cutting-Edge IT Training." },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);


  const services = [
    {
      heading: "Industry-Leading Expertise:",
      items: [
        "We bring years of experience in IT training and workforce development, providing cutting-edge programs designed by certified industry experts. Our trainers have real-world experience in software development, cloud computing, cybersecurity, data analytics, AI, and more, ensuring top-quality instruction.",
      ],
    },
    {
      heading: "Comprehensive & Customized Training Programs:",
      items: [
        "Our tailored training solutions cater to individuals, corporate teams, and enterprises. Whether you're looking for entry-level IT courses or advanced specialization in emerging technologies, we design programs to fit your unique learning needs and career goals.",
      ],
    },
    {
      heading: "Hands-On Learning Approach:",
      items: [ 
        "We emphasize practical experience through real-world projects, case studies, hands-on labs, live coding sessions, and interactive workshops. This ensures that learners gain job-ready skills and can immediately apply their knowledge in professional settings.",
      ],
    },
    {
      heading: "Flexible Learning Options:",
      items: [
        "We offer both online and on-site training, allowing professionals and organizations to choose the format that best fits their schedule. Our self-paced modules, live virtual sessions, and in-person workshops provide maximum flexibility without compromising on quality.",
      ],
    },
    {
      heading: "Strong Industry Network & Job Assistance:",
      items: [
        "We connect our trainees with a wide network of hiring managers, IT recruiters, and technology companies, providing resume assistance, interview preparation, and career guidance to help them secure high-paying IT roles.",
      ],
    },
    {
      heading: "Affordable & Value-Driven Training:",
      items: [
        "We provide high-quality IT training at competitive pricing, making professional upskilling accessible to individuals and businesses. Our cost-effective packages, corporate discounts, and customized training plans deliver maximum value.",
      ],
    },
    {
      heading: "Commitment to Excellence & Innovation:",
      items: [
        "Our mission is to empower IT professionals with best-in-class training solutions, ensuring that they excel in their careers and contribute to the success of their organizations. We are dedicated to continuous improvement, innovation, and delivering exceptional learning experiences.",
      ],
    },
  ];

  const trainingPrograms = [
    {
      title: "Software Development",
      description: "Full-Stack, Frontend, Backend, Java, Python, .NET, and more",
      icon: "fas fa-laptop-code"
    },
    {
      title: "Cloud Computing",
      description: "AWS, Azure, Google Cloud, DevOps, and Kubernetes",
      icon: "fas fa-cloud"
    },
    {
      title: "Cybersecurity",
      description: "Ethical Hacking, Network Security, Threat Analysis",
      icon: "fas fa-shield-alt"
    },
    {
      title: "Data Analytics & AI",
      description: "Machine Learning, Data Science, Big Data, Power BI",
      icon: "fas fa-chart-line"
    },
    {
      title: "Enterprise Solutions",
      description: "ERP, CRM, SAP, Oracle, Salesforce",
      icon: "fas fa-building"
    },
    {
      title: "Networking & Infrastructure",
      description: "Cisco, CCNA, Linux, Windows Server Administration",
      icon: "fas fa-network-wired"
    }
  ];

  const { pathname } = useLocation(); // Get the current route

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [pathname]);


  return (
    <>
       {/* Carousel Section */}
       <section className="carousl-section3">
        <div className="carousl-container3">
          <div
            className="carousel-slide4 zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="txt-overlay3">
              <p className="carousel-txt3">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="custom-section custom-bg-f2f2f2 custom-py-5">
        <div className="custom-bg1-image3">
          <div className="custom-content2">
            <h1>Comprehensive IT Training Services</h1>
            <p>
            Comprehensive IT Training Services provide hands-on learning in software development, cloud computing, cybersecurity, AI, and DevOps. Through interactive sessions, real-world projects, and certification preparation, individuals and businesses gain critical skills. Flexible online, on-site, and hybrid training options ensure workforce upskilling, improved productivity, and future readiness in the evolving tech industry.
            </p>
          </div>
        </div>
        <div className="custom-cont">
          <div className="custom-row">
          </div>
          <div className="custom-row">
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Empowering IT Professionals with Cutting-Edge Skills</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  At Carrier Launchers, we offer top-tier IT training programs designed to equip professionals with the latest technical skills and industry knowledge. Our training services focus on emerging technologies, software development, cloud computing, cybersecurity, data analytics, and enterprise solutions, ensuring that IT professionals stay competitive in a rapidly evolving industry.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Customized Training for Diverse IT Roles</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  We provide tailored training programs for software developers, system administrators, data analysts, cloud engineers, and IT consultants. Our courses include hands-on learning, real-world case studies, and certification preparation, helping individuals and organizations achieve their professional goals. Whether you're upskilling existing employees or training new talent, we offer flexible solutions to meet your needs.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Corporate IT Training for Workforce Development</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our corporate IT training programs enhance workforce efficiency, technical expertise, and business innovation. We collaborate with organizations to develop customized training roadmaps, ensuring their teams are proficient in industry-leading tools and best practices. Our expert trainers bring years of real-world experience to deliver high-impact learning experiences.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Certification & Exam Preparation</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  We offer comprehensive training for globally recognized IT certifications such as AWS, Microsoft Azure, Google Cloud, Cisco, CompTIA, PMP, and more. Our structured programs include exam preparation, practice tests, and hands-on labs, ensuring that candidates are fully prepared to achieve industry credentials and advance their careers.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Flexible Learning Options: Online & On-Site Training</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  To accommodate different learning preferences, we provide both online and on-site training options. Our virtual training sessions offer interactive learning experiences, while our on-site workshops deliver hands-on training for teams. We ensure accessibility and convenience, making it easier for professionals to upskill without disrupting their work schedules.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Advanced Technical Training in Emerging Technologies</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our training services cover cutting-edge technologies such as Artificial Intelligence (AI), Machine Learning (ML), Blockchain, Internet of Things (IoT), DevOps, Cybersecurity, Cloud Architecture, and Big Data Analytics. We empower IT professionals to gain in-depth knowledge and practical skills required to excel in today’s tech-driven landscape.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Hands-On Learning with Real-World Projects</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  We emphasize practical experience by incorporating real-world projects, case studies, simulations, and live coding sessions into our training modules. This approach enables participants to apply theoretical knowledge in practical environments, ensuring they are job-ready upon course completion.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Expert-Led Training by Industry Professionals</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Our courses are taught by certified trainers and industry experts with extensive experience in technology, IT consulting, and enterprise solutions. They provide valuable insights, mentorship, and best practices, helping learners stay ahead of industry trends and challenges.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-md-4 custom-mt-3">
              <div className="custom-cd">
                <div className="custom-cd-body custom-text-center">
                  <h5 className="custom-cd-title">Transform Your Career with IT Training</h5>
                  <div
                    className="custom-underline custom-d-block custom-mx-auto custom-mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="custom-cd-text">
                  Whether you are an individual looking to upskill or an organization aiming to train employees, our comprehensive IT training programs offer the perfect solution. With a focus on career advancement, workforce development, and technology-driven growth, Carrier Launchers is your trusted partner in IT training excellence.
                  </p>
                  <a href="/" className="custom-readmore">
                    read more --
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr/>
      <section>
      <div className="content-container">
        <h2 className="main-header">Why Choose Us?</h2>
        {services.map((service, index) => (
          <div key={index} className="service-section">
            <h3 className="sub-header">{service.heading}</h3>
            {service.items.map((item, itemIndex) => (
              <p key={itemIndex} className="content-text">{item}</p>
            ))}
          </div>
        ))}
      </div>
    </section>
    <section>
    <section className="training-programs-section py-5">
      <div className="container">
        <h2 className="text-center mb-5">Our IT Training Programs Include:</h2>
        <div className="row">
          {trainingPrograms.map((program, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="program-card text-center p-4">
                <i className={`${program.icon} fa-3x mb-3`}></i>
                <h3>{program.title}</h3>
                <p>{program.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </section>
    </>
  )
}
export default Training;
