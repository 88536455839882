import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../../../assets/css/Telecommunications.css"
//import { Container, Row, Col, Card } from 'react-bootstrap';
const Telecommunication = ({ title = "Connecting Telecommunication Businesses with Expert IT Professionals" }) => {

  const telecomServices = [
    {
      title: "5G & Network Infrastructure",
      content: "The demand for high-speed, low-latency connectivity is reshaping the telecom industry.",
      keyPoints: [
        "5G Network Design & Implementation – Optimizing next-generation wireless infrastructure.",
        "Edge Computing & IoT Integration – Enhancing network efficiency and device connectivity.",
        "Cloud-Native Network Functions (CNF) – Modernizing telecom architecture for agility and scalability.",
        "Network Security & Encryption – Protecting critical infrastructure from cyber threats."
      ]
    },
    {
      title: "Telecom Data Analytics & AI Solutions",
      content: "Data-driven insights are crucial for customer engagement, service optimization, and fraud prevention.",
      keyPoints: [
        "AI-Powered Network Optimization – Automating network management for peak performance.",
        "Big Data & Predictive Analytics – Enhancing customer experience and service reliability.",
        "Fraud Detection & Prevention – Implementing AI-driven security solutions against telecom fraud.",
        "Real-Time Billing & CRM Solutions – Improving revenue management and customer retention."
      ]
    },
    {
      title: "Cloud & Digital Transformation in Telecom",
      content: "Telecom companies are shifting towards cloud-based operations and automation for greater efficiency.",
      keyPoints: [
        "Cloud-Native Telecom Solutions – Implementing hybrid and multi-cloud strategies.",
        "Software-Defined Networking (SDN) & NFV – Enhancing network agility and scalability.",
        "Cybersecurity & Compliance – Ensuring regulatory adherence to FCC, GDPR, and PCI-DSS.",
        "Automation & DevOps in Telecom – Streamlining operations with AI-driven automation."
      ]
    }
  ];

   const { pathname } = useLocation(); // Get the current route
  
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when the route changes
    }, [pathname]);

  return (
    <>
    <section className="tele-container">
    <div className="tele-bg-image">
      <h2 className="tele-text animated-tele-text">
        {title}
        <span className="tele-underline"></span> {/* Underline Effect */}
      </h2>
    </div>
  </section>
  <section>
  <div className="telecom-container telecom-my-5">
      <div className="telecom-row">
        <div className="telecom-col-md-12">
          <h1 className="telecom-display-4 telecom-text-center telecom-mb-4">
            IT Bench Sales Recruitment for the Telecommunications Sector
          </h1>
          <p className="telecom-lead telecom-text-center">
            At <strong>Carrier Launchers</strong>, we specialize in IT Bench Sales Recruitment Services tailored for the Telecommunications industry, ensuring that companies have access to top IT professionals who drive network innovation, digital transformation, and cybersecurity enhancements. Our expertise spans across 5G network infrastructure, cloud computing, AI-driven analytics, IoT, and automation, enabling telecom providers to optimize operations and enhance customer experiences. With a strong network of direct clients, vendors, and hiring managers, we facilitate seamless placements and ensure quick turnaround times.
          </p>
        </div>
      </div>

      <div className="telecom-row telecom-mt-5">
        <div className="telecom-col-md-12">
          <h2 className="telecom-text-center telecom-mb-4">
            Tailored IT Staffing Solutions for the Telecom Industry
          </h2>
          <p className="telecom-text-center">
            We offer end-to-end recruitment services, including candidate sourcing, resume marketing, vendor management, contract negotiation, and onboarding support. Our IT talent pool includes professionals specializing in:
          </p>
          <ul className="telecom-list-group telecom-list-group-flush">
            <li className="telecom-list-group-item">
              <strong>5G & Network Engineering</strong> – Optimizing next-generation telecom networks for high-speed connectivity.
            </li>
            <li className="telecom-list-group-item">
              <strong>Cloud & Edge Computing</strong> – Enhancing telecom operations with scalable cloud-native solutions.
            </li>
            <li className="telecom-list-group-item">
              <strong>AI & Predictive Analytics</strong> – Automating network optimization, fraud detection, and customer insights.
            </li>
            <li className="telecom-list-group-item">
              <strong>Cybersecurity & Compliance</strong> – Protecting telecom infrastructure while ensuring regulatory adherence.
            </li>
            <li className="telecom-list-group-item">
              <strong>DevOps & Automation</strong> – Driving operational efficiency with AI-powered automation in telecom.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section>
 <div>
      {/* Header Section with Background Image */}
      <section className="tele-containerlast">
        <div className="tele-bg-imagelast">
          <h2 className="tele-textlast animated-tele-textlast">
            Industries We Serve: Telecommunications
            <p className='tele-center-textlast'>
            At Carrier Launchers, we provide expert IT Bench Sales Recruitment Services for the Telecommunications industry, helping companies accelerate digital transformation, network optimization, and cybersecurity. With the rapid evolution of 5G, cloud computing, AI-driven analytics, and IoT, telecom providers require skilled IT professionals to manage network infrastructure, customer experience solutions, and data security. We connect top-tier IT talent with telecom businesses, ensuring they stay ahead in a highly competitive and technology-driven market.
            </p>
            <span className="tele-underlinelast"></span> {/* Underline Effect */}
          </h2>
        </div>
      </section>

      {/* Cards Section */}
      <Container className="tele-card-containerlast">
        <Row>
          {telecomServices.map((service, index) => (
            <Col key={index} md={4} className="tele-mb-4last">
              <Card className="tele-h-100last">
                <Card.Body>
                  <Card.Title className="tele-card-titlelast">{service.title}</Card.Title>
                  <Card.Text className="tele-card-textlast">{service.content}</Card.Text>
                  <ul className="tele-ullast">
                    {service.keyPoints.map((point, i) => (
                      <li key={i} className="tele-lilast">{point}</li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  </section>
  </>
  );
}

export default Telecommunication;