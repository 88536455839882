import React from "react";
import "../../assets/css/About.css";
import Ab1 from "../../assets/Images/about1.jpeg"
import Ab2 from "../../assets/Images/about2.webp"
import Ab3 from "../../assets/Images/about3.avif"
import { FaTrophy, FaHandshake, FaUsers, FaChartLine } from "react-icons/fa";
import { Container, Row, Col, Card } from "react-bootstrap";
import Nidhi from "../../assets/Images/CEO.jpg";
import Rishi from "../../assets/Images/Account Manager.jpg";
import RishiM from "../../assets/Images/Director.jpg";
import Sudesh from "../../assets/Images/HR.jpg";

const About = () => {
  const whyChooseUsData = [
    // IT Consultants
    { title: "Faster Job Placements", description: "Reduce bench time and secure high-paying projects." },
    { title: "Access to Top Clients", description: "Work with direct clients, Fortune 500 companies, and leading vendors." },
    { title: "Expert Resume Marketing", description: "Get more interview calls with our targeted marketing approach." },
    { title: "Negotiation Support", description: "Get the best pay rates and contract terms." },
    { title: "Visa & Legal Assistance", description: "Hassle-free work authorization and compliance management." },
  
    // Companies & Employers
    { title: "Top-Tier IT Talent", description: "Pre-vetted, highly skilled consultants available for hiring." },
    { title: "Quick Hiring Process", description: "Fast turnaround time for contract and full-time roles." },
    { title: "Customized Staffing Solutions", description: "Tailored hiring strategies for business needs." },
    { title: "Cost-Effective Recruitment", description: "Reduce hiring costs while maintaining top quality." },
    { title: "Long-Term Partnerships", description: "Build relationships that help scale your business efficiently." },
  ];

  const achievements = [
    { icon: <FaTrophy />, text: "98% success rate in placing IT consultants within 2-4 weeks." },
    { icon: <FaHandshake />, text: "Long-term partnerships with Fortune 500 clients and leading staffing agencies." },
    { icon: <FaUsers />, text: "Strong retention rates with repeat placements and contract renewals." },
    { icon: <FaChartLine />, text: "1000+ successful placements in various industries." },
  ];
  const teamMembers = [
    {
      name: 'Nidhi Gupta',
      title: 'Founder & CEO',
      description: 'Nidhi Gupta, as the visionary leader of Carrier Launchers, drives innovation, strategic growth, and excellence in IT services. With expertise in digital transformation, cloud computing, and cybersecurity, they foster client success through cutting-edge solutions. Their people-centric leadership ensures sustainable business growth, industry leadership, and a future-ready, technology-driven enterprise.',
      image: Nidhi
    },
    {
      name: 'Rishi M',
      title: 'Director',
      description: 'Rishi M, as a key leader at Carrier Launchers, plays a crucial role in driving business strategy, operational excellence, and client success. With expertise in IT solutions, team management, and digital innovation, they ensure seamless execution of projects, foster strong partnerships, and contribute to the company long-term growth and industry leadership.',
      image: RishiM
    },
    {
      name: 'Rishi Mahajan',
      title: 'Account Manager',
      description: 'As an Account Manager at Carrier Launchers, Rishi Mahajan specializes in building strong client relationships, driving business growth, and ensuring customer satisfaction. With expertise in sales, negotiation, and strategic account management, they work closely with clients to understand their needs, deliver tailored solutions, and foster long-term partnerships for success.',
      image: Rishi 
    },
    {
      name: 'Sudesh Kumar',
      title: 'HR Manager',
      description: 'As an HR professional at Carrier Launchers, Sudesh Kumar plays a vital role in talent acquisition, employee engagement, and organizational development. They focus on recruiting top talent, fostering a positive work culture, and ensuring compliance with HR policies. Their expertise drives workforce productivity, professional growth, and long-term business success.',
      image: Sudesh
    }
  ]
  return (
    <>
      <section>
        <div className="about-container">
          <div className="about-content">
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid d-flex align-items-center main-container2">
          <div className="row align-items-center w-100 no-gutters">
            {/* Left Side Text */}
            <div className="col-md-6 text-container2">
              <h1>Who we are</h1>
              <p>At Carrier Launchers, we are a premier IT Bench Sales and Recruitment firm dedicated to connecting highly skilled IT professionals with top employers across the United States. With years of industry experience, we specialize in providing end-to-end staffing solutions, helping businesses find the right talent while ensuring IT consultants secure the best career opportunities.

                Our expertise lies in understanding the US IT job market, staffing trends, and visa sponsorship requirements (H1B, GC, OPT, CPT, TN, EAD). We work with a vast network of direct clients, prime vendors, and implementation partners, ensuring quick placements and successful career transitions for IT professionals.</p>
            </div>

            {/* Right Side Image */}
            <div className="col-md-6 image-container2">
              <div className="image-wrapper">
                <img src={Ab1} alt="Half Circle" className="half-circle-img2" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="fluid-container d-flex align-items-center outer-wrapper">
          <div className="row align-items-center w-100 no-spacing">
            {/* Left Side Image */}
            <div className="col-md-6 image-box">
              <div className="img-frame">
                <img src={Ab2} alt="Half Circle" className="circle-img" />
              </div>
            </div>

            {/* Right Side Text */}
            <div className="col-md-6 text-box">
              <h1>Our Vision</h1>
              <p>
              Our vision at Carrier Launchers is to become the most trusted and results-driven IT staffing partner in the US market by leveraging cutting-edge technology, a vast talent network, and industry expertise to create seamless and efficient hiring solutions. We strive to bridge the gap between top IT professionals and leading companies, ensuring minimal bench time, continuous career growth, and long-term success. By fostering a culture of transparency, innovation, and inclusivity, we aim to revolutionize IT recruitment, making the hiring process faster, smarter, and more efficient for both job seekers and businesses. 🚀
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid d-flex align-items-center main-container2">
          <div className="row align-items-center w-100 no-gutters">
            {/* Left Side Text */}
            <div className="col-md-6 text-container2">
              <h1>Our Mission</h1>
              <p>At Carrier Launchers, our mission is to empower IT professionals and businesses by bridging the gap between talent and opportunity in the US IT staffing industry. We strive to deliver efficient, reliable, and high-quality recruitment solutions that accelerate career growth for consultants and help businesses achieve their strategic objectives.</p>
              <br/>
              <p>
              <span><b><i>We are committed to:</i></b></span>
            </p><p>
              <span><b>✅ Minimizing Bench Time –</b></span> Quickly placing IT consultants in high-paying projects to ensure continuous career growth.</p>
              <p><span><b>✅ Maximizing Business Success –</b> </span>Providing top-tier IT talent to companies, helping them scale efficiently.</p>
              <p><span><b> ✅ Ensuring Compliance & Transparency –</b></span> Adhering to all legal, immigration, and industry regulations.</p>
              <p> <span><b>✅ Building Long-Term Partnerships – </b></span>Fostering relationships based on trust, integrity, and mutual success.</p>
              <p><span><b> ✅ Delivering Excellence – </b></span>Offering top-notch staffing, recruitment, and IT consulting services tailored to the ever-evolving job market.</p>
          </div>

          {/* Right Side Image */}
          <div className="col-md-6 image-container2">
            <div className="image-wrapper">
              <img src={Ab3} alt="Half Circle" className="half-circle-img2" />
            </div>
          </div>
        </div>
      </div>
    </section >
    <section>
      <Container className="why-choose-us">
        {/* Main Header */}
        <h2 className="text-center mb-4">Why Choose Us?</h2>
        <p className="para">
          At <b>Carrier Launchers</b>, we go beyond traditional staffing and recruitment to provide exceptional IT consulting and hiring solutions. Our expertise in IT bench sales recruitment ensures that professionals find the right job faster and that companies secure the best talent efficiently.
        </p>

        {/* First Section - For IT Consultants */}
        <h3 className="mt-4 why-h">For IT Consultants</h3>
        <Row>
          {whyChooseUsData.slice(0, 5).map((item, index) => (
            <Col key={index} md={4} sm={6} xs={12} className="mb-4">
              <Card className="why-card">
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Second Section - For Companies & Employers */}
        <h3 className="mt-4 why-h">For Companies & Employers</h3>
        <Row>
          {whyChooseUsData.slice(5).map((item, index) => (
            <Col key={index} md={4} sm={6} xs={12} className="mb-4">
              <Card className="why-card">
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
      <section>
      <div className="team-container">
      <h2>Meet Our Team</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div className="team-card" key={index}>
            <img src={member.image} alt={`${member.name}`} />
            <h3>{member.name}</h3>
            <p className="title">{member.title}</p>
            <p>{member.description}</p>
          </div>
        ))}
      </div>
    </div>
      </section>
    <section className="success-stories py-5 text-center">
      <Container>
        <h2 className="section-title">Our Success Stories</h2>
        <p className="section-subtitle">
          We take pride in helping IT professionals secure top roles and assisting businesses in hiring the best talent.
        </p>
        <Row className="mt-4">
          {achievements.map((achievement, index) => (
            <Col md={6} lg={3} key={index} className="mb-4">
              <Card className="achievement-card">
                <Card.Body>
                  <div className="icon-wrapper">{achievement.icon}</div>
                  <Card.Text>{achievement.text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>

    </>
  );
};

export default About;
