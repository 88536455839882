
import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card } from 'react-bootstrap';
import "../../../assets/css/Aerospace.css";
const Aerospace = ({ title = "Connecting Aerospace Businesses with Expert IT Professionals" }) => {

  const aerospaceIndustries = [
    {
      title: "Aerospace Manufacturing",
      content: "The aerospace industry demands precision engineering, automation, and secure data management.",
      keyPoints: [
        "ERP & PLM Solutions – Streamlining aircraft design, production, and supply chain logistics.",
        "AI-Driven Quality Control – Enhancing manufacturing accuracy through real-time analytics.",
        "IoT & Predictive Maintenance – Improving aircraft performance and minimizing downtime.",
        "Cybersecurity & Compliance – Ensuring adherence to ITAR, NIST, DFARS, and FAA regulations."
      ]
    },
    {
      title: "Defense & Military Systems",
      content: "Defense contractors require secure and efficient IT solutions to manage classified data, logistics, and mission-critical systems.",
      keyPoints: [
        "Cybersecurity & Threat Intelligence – Protecting sensitive defense data from cyber threats.",
        "Big Data & AI Analytics – Enhancing decision-making in military operations.",
        "Secure Communication Networks – Implementing encrypted, high-performance communication systems.",
        "Cloud & Edge Computing – Supporting real-time battlefield intelligence and analytics."
      ]
    },
    {
      title: "Space Exploration & Satellite Technology",
      content: "As space exploration advances, IT solutions play a crucial role in mission planning, data processing, and satellite management.",
      keyPoints: [
        "AI & Machine Learning for Space Missions – Optimizing spacecraft navigation and predictive analytics.",
        "Digital Twin Technology – Simulating space missions for testing and optimization.",
        "Advanced Robotics & Automation – Powering planetary exploration and robotic spacecraft.",
        "High-Performance Computing – Processing vast amounts of astronomical and mission data."
      ]
    }
  ];

   const { pathname } = useLocation(); // Get the current route
  
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top when the route changes
    }, [pathname]);

  return (
    <>
    <section>
    <section className="aero-container">
      <div className="aero-bg-image">
        <h2 className="aero-text animated-aero-text">
          {title}
          <span className="aero-underline"></span> {/* Underline Effect */}
        </h2>
      </div>
    </section>
    </section>
    <section>
    <div className="aer-container aer-my-5">
      <div className="aer-text-center aer-mb-5">
        <h1 className="aer-display-4 aer-font-weight-bold aer-text-primary">
          Expert IT Bench Sales Recruitment for the Aerospace Industry
        </h1>
        <p className="aer-lead">
          At <span className="aer-text-info">Carrier Launchers</span>, we specialize in IT Bench Sales Recruitment Services tailored for the Aerospace industry, connecting top-tier IT professionals with aerospace manufacturers, defense contractors, and space technology companies. Our expertise includes secure ERP & PLM systems, AI-driven quality control, digital twin technology, cybersecurity, IoT, and advanced data analytics. With a strong network of direct clients, vendors, and hiring managers, we ensure seamless hiring and quick placements to meet the evolving demands of the aerospace sector.
        </p>
      </div>

      <div className="aer-row">
        <div className="aer-col-md-8 aer-offset-md-2">
          <h2 className="aer-text-center aer-mb-4 aer-text-secondary">
            Tailored IT Staffing Solutions for Aerospace & Defense
          </h2>
          <p className="aer-text-muted">
            We provide comprehensive staffing solutions, including candidate sourcing, resume marketing, vendor management, contract negotiation, and onboarding support, ensuring access to specialized IT talent. Our recruitment services cover:
          </p>
          <ul className="aer-list-group aer-list-group-flush">
            <li className="aer-list-group-item">
              <strong>Secure ERP & PLM Systems</strong> – Managing complex engineering and manufacturing processes.
            </li>
            <li className="aer-list-group-item">
              <strong>AI-Powered Quality Control</strong> – Enhancing defect detection and precision manufacturing.
            </li>
            <li className="aer-list-group-item">
              <strong>Digital Twin Technology</strong> – Enabling virtual simulations for prototyping and performance analysis.
            </li>
            <li className="aer-list-group-item">
              <strong>Cybersecurity & Compliance</strong> – Ensuring adherence to ITAR, NIST, and DFARS regulations.
            </li>
            <li className="aer-list-group-item">
              <strong>IoT & Predictive Maintenance</strong> – Optimizing aircraft maintenance and operational efficiency.
            </li>
          </ul>
        </div>
      </div>
    </div>
    </section>
    <section>
    <div>
      {/* Header Section with Background Image */}
      <div className="aerospace-header-section">
        <Container>
          <Row className="aerospace-justify-content-center">
            <Col md={8} className="aerospace-text-center">
              <h1>Industries We Serve: Aerospace</h1>
              <p>
                At Carrier Launchers, we provide specialized IT Bench Sales Recruitment Services for the Aerospace industry, helping organizations integrate cutting-edge technology into their operations. The aerospace sector relies on advanced IT solutions to enhance manufacturing, defense systems, space exploration, cybersecurity, and operational efficiency.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Cards Section */}
      <Container className="aerospace-card-container">
        <Row>
          {aerospaceIndustries.map((industry, index) => (
            <Col key={index} md={4} className="aerospace-mb-4">
              <Card className="aerospace-h-100">
                <Card.Body>
                  <Card.Title className="aerospace-card-title">{industry.title}</Card.Title>
                  <Card.Text className="aerospace-card-text">{industry.content}</Card.Text>
                  <ul className="aerospace-ul">
                    {industry.keyPoints.map((point, i) => (
                      <li key={i} className="aerospace-li">{point}</li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
    </section>
    </>
  )
}

export default Aerospace