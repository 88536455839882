import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import "../../../assets/css/Retail.css";
import { Container, Row, Col, Card } from "react-bootstrap";

const Retail = ({ title = "Connecting Retail & E-Commerce Businesses with Expert IT Professionals" }) => {


const retailIndustries = [
  {
    title: "Brick-and-Mortar Retail",
    description:
      "Traditional retail is embracing digitalization through AI-powered analytics, POS systems, and smart inventory management. We provide IT talent for ERP solutions, RFID-based tracking, customer analytics, and cybersecurity to enhance in-store operations and customer engagement."
  },
  {
    title: "E-Commerce & Online Marketplaces",
    description:
      "E-commerce companies require robust platforms, AI-driven recommendations, and secure payment gateways. Our IT staffing solutions include full-stack development, cloud computing, fraud prevention, chatbots, and digital marketing automation to enhance user experience and scalability."
  },
  {
    title: "Supply Chain & Logistics",
    description:
      "Retail logistics depend on AI-powered demand forecasting, real-time tracking, and automated fulfillment centers. We provide IT professionals for WMS, TMS, IoT-driven fleet management, and last-mile delivery optimization to enhance supply chain efficiency."
  },
  {
    title: "Omnichannel Retailing",
    description:
      "Modern retail integrates online and offline experiences through omnichannel strategies. We provide IT experts in CRM, cloud-based order management, AI-driven personalization, and seamless payment integration to create a unified customer journey."
  },
  {
    title: "Retail Cybersecurity & Fraud Prevention",
    description:
      "With increasing cyber threats in retail, businesses need strong security measures. We provide IT professionals specializing in data encryption, fraud detection, PCI DSS compliance, and AI-driven threat prevention to safeguard customer transactions and sensitive data."
  }
];

 const { pathname } = useLocation(); // Get the current route

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [pathname]);

  return (
    <>
    <section className="retail-container">
        <div className="retail-bg-image">
          <h2 className="retail-text animated-retail-text">
            {title}
            <span className="retail-underline"></span> {/* Underline Effect */}
          </h2>
        </div>
      </section>
      <section>
      <div className="recruitment-container">
      <h1 className="recruitment-title">
        Premier IT Bench Sales Recruitment for Retail & E-Commerce
      </h1>

      <p className="recruitment-intro">
        At <strong>Carrier Launchers</strong>, we provide specialized IT Bench Sales Recruitment Services for the 
        Retail & E-Commerce industry, ensuring businesses stay competitive in the digital marketplace.
      </p>

      <p className="recruitment-description">
        Our expertise spans omnichannel retail solutions, AI-driven customer analytics, 
        e-commerce platform development, POS systems, cloud computing, cybersecurity, and supply chain automation. 
        With a strong network of direct clients, vendors, and hiring managers, we ensure quick placements and seamless 
        hiring to meet the fast-paced demands of the retail sector.
      </p>

      <h2 className="recruitment-subheading">
        Tailored IT Staffing Solutions for Digital Retail Transformation
      </h2>

      <p className="recruitment-text">
        We offer comprehensive staffing solutions, including candidate sourcing, resume marketing, 
        vendor management, contract negotiation, and onboarding support. Our talent pool includes professionals skilled 
        in AI-driven personalization, mobile commerce, digital payments, inventory management systems, CRM, and cybersecurity.
      </p>
      
      <p className="recruitment-compliance">
        We also ensure compliance with industry standards such as <strong>PCI-DSS, GDPR, and data protection regulations</strong>.
      </p>

      <h2 className="recruitment-subheading">
        Expert IT Talent for E-Commerce & Retail Innovation
      </h2>

      <p className="recruitment-focus">Our recruitment services focus on:</p>
      <ul className="recruitment-list">
        <li className="recruitment-list-item">✅ <strong>Omnichannel Retail Solutions</strong> – Integrating online and offline shopping experiences seamlessly.</li>
        <li className="recruitment-list-item">✅ <strong>AI-Powered Customer Analytics</strong> – Leveraging data insights to personalize shopping experiences.</li>
        <li className="recruitment-list-item">✅ <strong>E-Commerce Platform Development</strong> – Hiring developers skilled in Magento, Shopify, WooCommerce, and custom-built platforms.</li>
        <li className="recruitment-list-item">✅ <strong>Cloud Computing & Cybersecurity</strong> – Ensuring scalable and secure IT infrastructure for digital commerce.</li>
        <li className="recruitment-list-item">✅ <strong>POS & Digital Payment Systems</strong> – Recruiting experts in mobile payments, NFC technology, and blockchain security.</li>
        <li className="recruitment-list-item">✅ <strong>Supply Chain & Inventory Automation</strong> – Enhancing efficiency with AI-driven logistics and predictive demand planning.</li>
      </ul>

      <h2 className="recruitment-subheading">
        Your Trusted Recruitment Partner for Future-Ready Retail IT
      </h2>

      <p className="recruitment-closing">
        Whether you need IT professionals for e-commerce website development, AI-driven customer engagement, or enterprise 
        retail management solutions, <strong>Carrier Launchers</strong> is your dedicated hiring partner.
      </p>

      <p className="recruitment-summary">
        Our industry expertise, vast talent network, and commitment to excellence ensure that we deliver top-tier IT 
        professionals who drive success in the dynamic Retail & E-Commerce landscape.
      </p>
    </div>
      </section>
      <section>
      <Container className="retail-container py-5">
      <div className="text-center retail-header">
        <h1>Industries We Serve in Retail & E-Commerce</h1>
        <p>
          At <strong>Carrier Launchers</strong>, we provide IT Bench Sales Recruitment Services for the 
          Retail & E-Commerce sector, helping businesses implement digital transformation. With AI-driven 
          personalization, omnichannel strategies, cybersecurity, and automation, retailers require top 
          IT professionals to enhance customer experience, optimize supply chains, and drive operational 
          efficiency in a fast-evolving market.
        </p>
      </div>
      <Row className="g-4">
        {retailIndustries.map((industry, index) => (
          <Col key={index} md={4} sm={6} xs={12}>
            <Card className="retail-card h-100">
              <Card.Body>
                <Card.Title>{industry.title}</Card.Title>
                <Card.Text>{industry.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
      </section>
      </>
 )
}

export default Retail