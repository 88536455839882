import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import "../../../assets/css/Manufacturing.css";
import { Container, Row, Col, Card } from "react-bootstrap";
const Manufacturing = ({ title = "Manufacturing Industry" }) => {



const manufacturingIndustries = [
  {
    title: "Automotive Manufacturing",
    description:
      "The automotive industry is transforming with smart factories, IoT-enabled production, and AI-driven predictive maintenance. Our IT staffing solutions include ERP optimization, connected vehicle technology, AI-powered diagnostics, and automation to enhance production efficiency and precision."
  },
  {
    title: "Aerospace & Defense",
    description:
      "Aerospace and defense sectors demand secure, compliant IT solutions for efficiency, quality control, and regulations. We provide IT talent for ERP systems, AI-powered quality control, digital twin technology, and compliance solutions ensuring industry adherence."
  },
  {
    title: "Consumer Goods & Packaging",
    description:
      "Consumer goods manufacturing relies on AI-driven demand forecasting, supply chain automation, and smart packaging solutions. We provide IT professionals for cloud-based inventory management, sustainable manufacturing, IoT tracking, and AI-driven market analytics."
  },
  {
    title: "Electronics & Semiconductor Manufacturing",
    description:
      "Semiconductor and electronics manufacturing depend on IoT, AI, and cloud solutions for efficiency. We support IT staffing for defect detection, predictive maintenance, supply chain transparency, edge computing, and automation in chip fabrication."
  },
  {
    title: "Pharmaceutical & Biotech Manufacturing",
    description:
      "Pharmaceutical and biotech industries require IT solutions for compliance, research, and production. We provide experts in AI-driven drug discovery, smart warehousing, digital transformation, and regulatory-compliant IT solutions for seamless operations."
  },
  {
    title: "Heavy Machinery & Industrial Equipment",
    description:
      "Industrial equipment manufacturing benefits from IoT, robotics, and AI-driven diagnostics. Our IT recruitment services include predictive maintenance, automated assembly lines, digital thread technology, and IIoT for real-time monitoring to improve productivity."
  }
];

 const { pathname } = useLocation(); // Get the current route

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [pathname]);
  
  return (
    <>
    <section className="manu-container">
        <div className="manu-bg-image">
          <h2 className="manu-text animated-manu-text">
            {title}
            <span className="manu-underline"></span> {/* Underline Effect */}
          </h2>
        </div>
      </section>
      <section>
      <div className="manufacturing-container">
      {/* Dynamic and Stylish Heading */}
      <h2 className="manufacturing-heading">
        Expert IT Bench Sales Recruitment for the Manufacturing Industry
      </h2>

      <div className="container mt-4">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="manufacturing-card">
              <h3 className="manufacturing-card-title">
                Tailored IT Staffing Solutions for Smart Manufacturing
              </h3>
              <p className="manufacturing-card-text">
                At <strong>[Your Company Name]</strong>, we provide IT Bench Sales Recruitment Services tailored for the Manufacturing sector, helping businesses find top IT professionals to drive digital transformation and operational efficiency. We specialize in sourcing talent for:
              </p>
              <ul className="manufacturing-list">
                <li>ERP & Supply Chain Management</li>
                <li>Industrial Automation & IoT</li>
                <li>Robotics & Predictive Maintenance</li>
                <li>Cloud Manufacturing & Cybersecurity</li>
              </ul>
              <p className="manufacturing-card-text">
                With a strong network of direct clients, vendors, and hiring managers, we ensure seamless placements and quick turnaround times to meet the evolving demands of the manufacturing industry.
              </p>

              <h4 className="manufacturing-subtitle">Why Choose Us?</h4>
              <p className="manufacturing-card-text">
                Our services include candidate sourcing, resume marketing, vendor management, contract negotiation, and onboarding support, ensuring that manufacturing firms have access to top-tier IT talent.
              </p>
              <ul className="manufacturing-list">
                <li>Expertise in MES, PLM, and AI-powered analytics</li>
                <li>Smart factories and digital twin technologies</li>
                <li>Compliance with ISO, OSHA, NIST, and cybersecurity standards</li>
                <li>Optimized recruitment for production, supply chain analytics, and automation</li>
              </ul>

              <p className="manufacturing-card-footer">
                <strong>Contact us today</strong> to build a skilled IT workforce that powers the future of manufacturing!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
      </section>
      <section>
      <Container className="manufacturing-container py-5">
      <div className="text-center manufacturing-header">
        <h1>Industries We Serve in Manufacturing</h1>
        <p>
          At Carrier Launchers, we specialize in IT Bench Sales Recruitment Services
          for the Manufacturing Industry, helping businesses integrate cutting-edge technology. 
          With Industry 4.0, IoT, AI, and automation, manufacturers require skilled IT professionals 
          to enhance efficiency, optimize supply chains, and drive digital transformation in an evolving industrial landscape.
        </p>
      </div>
      <Row className="g-4">
        {manufacturingIndustries.map((industry, index) => (
          <Col key={index} md={4} sm={6} xs={12}>
            <Card className="manufacturing-card h-100">
              <Card.Body>
                <Card.Title>{industry.title}</Card.Title>
                <Card.Text>{industry.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
      </section>
      </>
  )
}

export default Manufacturing
