import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from './components/Footer';
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import FAQ from "./components/pages/FAQ";
import Sourcing from "./components/pages/Services/ITSourcing";
import CS from "./components/pages/Services/Consulting";
import Training from "./components/pages/Services/Training";
import BPO from "./components/pages/Services/BPO";
import Bank from "./components/pages/Industries/Banking";
import Media from "./components/pages/Industries/Media";
import Manufacturing from "./components/pages/Industries/Manufacturing";
import Healthcare from "./components/pages/Industries/Healthcare";
import Issurance from "./components/pages/Industries/Issurance";
import Public from "./components/pages/Industries/Public";
import Aerospace from "./components/pages/Industries/Aerospace";
import Telecommunication from "./components/pages/Industries/Telecommunications";
import Retail from "./components/pages/Industries/Retail";

function App() {
  return (
    <div className="App">
      <Router>
      <Navbar />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services/it-sourcing" element={<Sourcing />} />
          <Route path="/services/consulting-services" element={<CS />} />
          <Route path="/services/it-training" element={<Training />} />
          <Route path="/services/business-process-services" element={<BPO />} />
          <Route path="/industries/banking" element={<Bank />} />
          <Route path="/industries/media" element={<Media />} />
          <Route path="/industries/manufacturing" element={<Manufacturing />} />
          <Route path="/industries/issurance" element={<Issurance />} />
          <Route path="/industries/retail" element={<Retail />} />
          <Route path="/industries/healthcare" element={<Healthcare />} />
          <Route path="/industries/public-services" element={<Public />} />
          <Route path="/industries/aerospace" element={<Aerospace />} />
          <Route path="/industries/telecommunications" element={<Telecommunication />} />
        </Routes>
        <Footer />
    </Router>
    </div>
  );
}

export default App;