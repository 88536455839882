import React, { useState, useEffect, useRef  } from 'react';
import { useLocation } from "react-router-dom";
import emailjs from "emailjs-com";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import BS1 from '../../../assets/Images/BS1.webp'
import BS2 from '../../../assets/Images/BS2.avif';
import BS3 from '../../../assets/Images/BS3.jpg';
import BS4 from '../../../assets/Images/BS4.avif';
import BS6 from '../../../assets/Images/BS6.avif';
import BS7 from '../../../assets/Images/BS7.jpg';
import BS8 from '../../../assets/Images/BS8.avif';
import "../../../assets/css/Sourcing.css";

const BenchSalesStaffing = () => {
  const slides = [
    { image: BS1, text: "Empowering Business Growth with the Right IT Talent" },
    { image: BS2, text: "Connecting Visionary Talent with Strategic Opportunities" },
    { image: BS3, text: "Bridging Talent and Opportunity for Future Success" },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval);
  }, [slides.length]);

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contact: "",
    jobInterested: "",
    description: "",
  });

  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Form submission handler using EmailJS
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Log the form data for debugging
    console.log("Form Data to Send:", formData);

    // Construct the template params to send to EmailJS
    const templateParams = {
      fullName: formData.fullName,
      email: formData.email,
      contact: formData.contact,
      jobInterested: formData.jobInterested,
      description: formData.description,
    };

    try {
      // Send the email using the EmailJS service
      const result = await emailjs.send(
        "service_ofyikql", // Replace with your actual EmailJS service ID
        "template_4ll1wd8", // Replace with your actual EmailJS template ID
        templateParams,
        "jtpwxCcbI-QI6Izqa" // Replace with your EmailJS user ID
      );

      // Log the result for debugging
      console.log("EmailJS result:", result);

      if (result.status === 200) {
        setMessage("Thank you for your application! We’ve received your details. Our team will review your information and contact you if your profile matches the position. We’ll be in touch soon!");
        setIsSuccess(true);
      } else {
        throw new Error("There was an issue submitting your application. Please try again later or contact us at [hr@carrierlaunchers.com].");
      }
    } catch (error) {
      // Log and display any errors that occur during the email send
      console.error("Error sending email:", error);
      setMessage("An error occurred while sending the email. Please try again.");
      setIsSuccess(false);
    }
  };

  const { pathname } = useLocation(); // Get the current route

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [pathname]);

  const formRef = useRef(null);

  const scrollToForm = (e) => {
    e.preventDefault();
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {/* Carousel Section */}
      <section className="carousl-section1">
        <div className="carousl-container1">
          <div
            className="carousel-slide2 zoom-effect fade-effect"
            style={{
              backgroundImage: `url(${slides[currentSlide].image})`,
            }}
          >
            <div className="txt-overlay1">
              <p className="carousel-txt1">{slides[currentSlide].text}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bench-staffing">
        <h3 className="section-titl">About Our IT Staffing Services:</h3>
        <hr className="divider2" />
        <div className="contxt1">
          <div className="pic-container1">
            <img src={BS4} alt="Bench" className="animated-pic" />
          </div>
          <div className="textt1">
            <p>
              At Carrier Launchers, our Bench Sales Staffing Services are designed to support organizations in optimizing their IT workforce. We specialize in identifying and placing skilled IT professionals who are on the bench, ensuring they are swiftly matched with relevant client projects. Our approach is rooted in a deep understanding of both our clients' business needs and our candidates' expertise. Whether you need immediate support for a project or are looking for long-term strategic placements, we provide tailored solutions that streamline the staffing process and ensure that businesses maintain productivity without any unnecessary delays.
            </p>
            <p>
              Our objective is to bridge the gap between top-tier IT professionals and organizations seeking specialized skills. By leveraging our extensive network, we connect talented professionals who are available for placement with businesses that need their expertise. Our recruitment team works relentlessly to provide quick, high-quality placements, ensuring that your team is always equipped with the best talent, whether it’s for short-term projects, long-term contracts, or permanent placements.Our goal is to drive success for our clients and help shape the future of IT staffing with efficiency, integrity, and innovation.
            </p>
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="sect-title">
                Our <span className="text-danger">Bench sales</span> Expertise
              </h2>
              <div
                className="underline mx-auto mb-3"
                style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
              ></div>
              <p className="sect-subtitle">
                The best services provided by us
              </p>
            </div>
          </div>

          {/* First Quote */}
          <div className="row">
            <div className="col-12 mb-4">
              <div className="quote-card shadow">
                <div className="quote-img-wrapper">
                  <img
                    src={BS6} // Circular Image Placeholder
                    alt="C2C"
                    className="quote-img rounded-circle"
                  />
                </div>
                <div className="quote-content">
                  <h3 className="quote-header">C2C Staffing</h3>
                  <p className="quote-description">
                    At Carrier Launchers, we provide expert C2C (Corp-to-Corp) staffing solutions tailored for IT professionals.
                    Our services connect businesses with highly skilled consultants on a contract basis, offering flexibility and
                    cost savings. We specialize in sourcing top talent across industries, ensuring the right fit for your project
                    needs. With a streamlined process for contract management, legal compliance, and ongoing support, we help
                    businesses scale quickly without long-term commitments. Our experienced bench sales team works to match
                    clients with the best consultants, ensuring high-quality, efficient staffing solutions to meet your
                    evolving business demands.
                  </p>
                 <a href="/" className="btnn quote-btn" onClick={scrollToForm}>
          Learn more--
        </a>
                </div>
              </div>
            </div>

            {/* Second Quote */}
            <div className="col-12 mb-4">
              <div className="quote-card shadow">
                <div className="quote-img-wrapper">
                  <img
                    src={BS7} // Circular Image Placeholder
                    alt="W2"
                    className="quote-img rounded-circle"
                  />
                </div>
                <div className="quote-content">
                  <h3 className="quote-header">W2 Staffing</h3>
                  <p className="quote-description">
                    At Carrier Launchers, our W2 staffing services provide businesses with reliable, full-time or contract-to-hire employees who
                    work directly as W2 employees. We specialize in sourcing top-tier talent across industries, ensuring a seamless recruitment
                    process that aligns with your specific project needs. Our W2 staffing solutions offer clients the flexibility to hire professionals
                    for both short-term projects and long-term roles. By managing all tax and compliance matters, we make it easy for businesses
                    to onboard qualified professionals quickly while reducing administrative burdens. Trust us to deliver highly skilled candidates who
                    integrate seamlessly into your team.
                  </p>
                  <a href="/" className="btnn quote-btn" onClick={scrollToForm}>
          Learn more--
        </a>
                </div>
              </div>
            </div>

            {/* Third Quote */}
            <div className="col-12 mb-4">
              <div className="quote-card shadow">
                <div className="quote-img-wrapper">
                  <img
                    src={BS8} // Circular Image Placeholder
                    alt="1099"
                    className="quote-img rounded-circle"
                  />
                </div>
                <div className="quote-content">
                  <h3 className="quote-header">Fulltime Staffing</h3>
                  <p className="quote-description">
                    At Carrier Launchers, our full-time staffing solutions help businesses build a highly skilled and dedicated workforce. We specialize in sourcing, screening, and placing top talent across industries such as IT, finance, healthcare, manufacturing, and retail. Our recruitment process ensures that companies find professionals who align with their job requirements, company culture, and long-term business goals. We handle everything from talent sourcing and interviews to background verification and onboarding, providing a seamless hiring experience. With a strong focus on quality, retention, and scalability, our full-time staffing services enable businesses to secure the right talent while minimizing hiring risks and reducing turnover. Whether you need to fill a single position or build an entire team, Carrier Launchers is committed to delivering reliable and future-ready workforce solutions that drive business success.
                  </p>
                   <a href="/" className="btnn quote-btn" onClick={scrollToForm}>
          Learn more--
        </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section>
      <div className="bs1">
        <h3 className="header">Why Choose Our Bench Sales Staffing Solutions?</h3>
        <ol className="paragraph-list">
          <li>
            <h4 className="paragraph-heading">Quick Turnaround Time:</h4>
            <p>
              We understand the urgency of staffing needs, and our efficient process ensures that you get the right consultant quickly, without compromising on quality. We specialize in minimizing downtime and accelerating project timelines.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Access to Top Talent:</h4>
            <p>
              Our extensive bench consultant pool includes highly skilled professionals with expertise across various technologies and industries. We provide you with access to the best available talent, ensuring you get a consultant who fits your exact requirements.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Tailored Staffing Solutions:</h4>
            <p>
              We take the time to understand your business goals and staffing needs. Our solutions are customized to meet your specific requirements, whether you need a short-term placement or a long-term team member.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Comprehensive Screening Process:</h4>
            <p>
              We employ a rigorous screening and selection process to ensure that only the most qualified candidates are placed. Each consultant undergoes thorough interviews, skill assessments, and background checks.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Dedicated Account Management:</h4>
            <p>
              Our expert account managers work closely with you throughout the engagement. From initial discussions to consultant placement and follow-up, we ensure continuous support and communication to meet your business objectives.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Cost-Effective Solutions:</h4>
            <p>
              With our bench sales staffing model, you can quickly scale your team without the overhead costs associated with traditional hiring methods. We offer flexible pricing structures to ensure you get the best value.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Proven Track Record:</h4>
            <p>
              We have successfully provided staffing solutions for clients across various industries. Our proven track record of successful consultant placements demonstrates our ability to meet even the most complex staffing requirements.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Ongoing Support and Retention:</h4>
            <p>
              Our support doesn’t end after placement. We continually monitor the performance of our consultants and ensure that they’re delivering results. We focus on consultant retention and always look for opportunities to upskill and grow the talent pool.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Scalable Solutions:</h4>
            <p>
              Whether you need one consultant or an entire team, our staffing solutions scale with your business needs. We can quickly provide multiple consultants for larger projects or teams.
            </p>
          </li>
          <li>
            <h4 className="paragraph-heading">Flexible Engagement Models:</h4>
            <p>
              We offer flexible engagement models (C2C, W2, 1099) to meet your specific business and legal requirements, making it easier for you to manage consultant contracts.
            </p>
          </li>
        </ol>
        <p>
          By choosing our Bench Sales Staffing Solutions, you're choosing a partner who is committed to delivering the right talent, on time, every time. Let us help you meet your project goals with ease and efficiency.
        </p>
      </div>
    </section>
    <section className="appointmentl ref={formRef}">
    <Container className="mt-5">
      <Row>
        <Col md={6} className="offset-md-0">
          <h2 className="text-center">Join Our Team</h2>
          <p>We’re thrilled that you’re interested in joining our team! Please complete the form below with your details. Our team will review your application and contact you if your profile aligns with the position.</p>
          {message && (
            <Alert variant={isSuccess ? "success" : "danger"}>{message}</Alert>
          )}
          <Form onSubmit={handleSubmit}>
            {/* Full Name */}
            <Form.Group controlId="fullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your full name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* Email Address */}
            <Form.Group controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* Contact Number */}
            <Form.Group controlId="contact">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter contact number"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* Job Interested */}
            <Form.Group controlId="jobInterested">
              <Form.Label>Which job are you interested in?</Form.Label>
              <Form.Control
                type="text"
                name="jobInterested"
                value={formData.jobInterested}
                onChange={handleChange}
                placeholder="e.g: Java Developer"
                required
              />
            </Form.Group>

            {/* Description */}
            <Form.Group controlId="description">
              <Form.Label>Tell us about yourself</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Write a short description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button className="Btnt"type="submit">Submit your Application</Button>
            <p>For a quicker response, please email your resume to hr@carrierlaunchers.com</p>
          </Form>
        </Col>
      </Row>
    </Container>
    </section>

    </>

  )
}



export default BenchSalesStaffing;
