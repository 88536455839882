import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import "../../assets/css/Home.css"; // Import CSS file
import video1 from "../../assets/Images/Video1.mp4"; // Import your video file
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import bb from "../../assets/Images/BB.avif"
import bb1 from "../../assets/Images/bb1.avif"
import bb2 from "../../assets/Images/bb2.jpg"
import bb3 from "../../assets/Images/bb3.jpg"
import bb4 from "../../assets/Images/bb4.jpg"
import bb5 from "../../assets/Images/bb5.webp"
import bb6 from "../../assets/Images/bb6.webp"
import H1 from "../../assets/Images/H1.webp"
import H2 from "../../assets/Images/H2.webp"
import H3 from "../../assets/Images/H3.webp"
import H4 from "../../assets/Images/H4.avif"
import F from "../../assets/Images/F.jpg"
import I from "../../assets/Images/I.webp"
import M from "../../assets/Images/M.jpg"
import MA from "../../assets/Images/MA.jpg"
import P from "../../assets/Images/P.jpg"
import E from "../../assets/Images/E.webp"
import T from "../../assets/Images/T.avif"
import A from "../../assets/Images/A.avif"
import HC from "../../assets/Images/HC.jpg"
import Amp from "../../assets/Images/amp-site.svg"
import Fisher from "../../assets/Images/fisher_investments.png"
import AT from "../../assets/Images/att.png"
import Capital from "../../assets/Images/capital.png"
import Charter from "../../assets/Images/Charter.png"
import Cigna from "../../assets/Images/cignahealthcare.png"
import JPM from "../../assets/Images/jpmorgan.png"
import USB from "../../assets/Images/us_bank.png"
import Verizon from "../../assets/Images/verizon.png"
import S11 from '../../assets/Images/S11.avif';
import S12 from '../../assets/Images/S12.jpg';
import S13 from '../../assets/Images/S13.jpg';
import S14 from '../../assets/Images/S14.avif';
import S15 from '../../assets/Images/S15.avif';
import S16 from '../../assets/Images/S16.jpg';

const Home = () => {
  const clients = [
    { name: "Capital", logo: Capital },
    { name: "Fisher Investments", logo: Fisher },
    { name: "Ameriprise Financial", logo: Amp },
    { name: "AT&T", logo: AT },
    { name: "Charter", logo: Charter },
    { name: "Cigna", logo: Cigna },
    { name: "JP Morgan", logo: JPM },
    { name: "US Bank", logo: USB },
    { name: "Verizon", logo: Verizon },
  ];

  const images = [
    { id: 1, src: S11, alt: '' },
    { id: 2, src: S12, alt: '' },
    { id: 3, src: S13, alt: '' },
    { id: 4, src: S14, alt: '' },
    { id: 5, src: S15, alt: '' },
    { id: 6, src: S16, alt: '' },
  ];
  
  const { pathname } = useLocation(); // Get the current route

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [pathname]);


  return (
    <>
      <section>
        <div className="home-container">
          {/* Background Video */}
          <video autoPlay loop muted className="background-video">
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          {/* Dark Overlay for Readability */}
          <div className="overlay"></div>

          {/* Centered Content */}
          <div className="content">
            <h1>Welcome to Carrier Launchers – Your Trusted Partner in US IT staffing</h1>
          </div>
        </div>
      </section>
      <section className="solutions-section">
      {/* Common Header */}
      <h2 className="section-title">Our Highly Reliable Solutions</h2>
      <p className="section-description">
        At Carrier Launchers, we specialize in connecting top IT talent with leading businesses across the United States. 
        Our proven Bench Sales Recruitment strategies help optimize workforce utilization, ensuring that your consultants find the right projects quickly.
      </p>

      {/* Carousel */}
      <Carousel indicators={true} controls={true} interval={10000} pause={false} fade>
        {/* Slide 1 */}
        <Carousel.Item>
          <div className="carousel-content">
            <img src={bb} alt="Consulting Solutions" className="carousel-image" />
            <div className="carousel-text">
              <div className="solution-card">
                <h3 className="card-title">Targeted Marketing & Placement</h3>
                <p className="card-text">
                  We leverage an extensive network of prime vendors, direct clients, and implementation partners to market available bench consultants efficiently.
                </p>
                <a href="/" className="card-button">Learn More</a>
              </div>
            </div>
          </div>
        </Carousel.Item>

        {/* Slide 2 */}
        <Carousel.Item>
          <div className="carousel-content">
            <img src={bb1} alt="Another Solution" className="carousel-image" />
            <div className="carousel-text">
              <div className="solution-card">
                <h3 className="card-title">Relationship-Driven Sales Approach</h3>
                <p className="card-text">
                  Our dedicated account managers build and maintain long-term relationships with Tier-1 vendors, direct clients, and system integrators.
                </p>
                <a href="/" className="card-button">Learn More</a>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
            <div className="carousel-content">
              <img src={bb2} alt="Third Solution" className="carousel-image" />
              <div className="carousel-text">
                <div className="solution-card">
                  <h3 className="card-title">Compliance & Documentation Support</h3>
                  <p className="card-text">
                    We assist in managing H-1B, CPT, OPT, GC, and EAD documentation, ensuring compliance with US employment regulations.
                    Our legal and HR teams provide seamless support for onboarding, contract negotiations, and immigration processes.
                  </p>
                  <a href="/"
                    className="card-button">Learn More</a>

                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-content">
              <img src={bb3} alt="Third Solution" className="carousel-image" />
              <div className="carousel-text">
                <div className="solution-card">
                  <h3 className="card-title">AI-Driven Recruitment & Automated Matching</h3>
                  <p className="card-text">
                    Our AI-powered recruitment system streamlines job matching, optimizing search and submission time.
                    We utilize cutting-edge ATS (Applicant Tracking Systems) and CRM tools for real-time tracking and efficient communication.
                  </p>
                  <a href="/"
                    className="card-button">Learn More</a>

                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-content">
              <img src={bb4} alt="Third Solution" className="carousel-image" />
              <div className="carousel-text">
                <div className="solution-card">
                  <h3 className="card-title">24/7 Support & Dedicated Sales Team</h3>
                  <p className="card-text">
                    Our round-the-clock support ensures that our clients and consultants receive prompt assistance at any time.
                    Our highly skilled bench sales recruiters work tirelessly to place candidates in contract, contract-to-hire, and direct-hire roles.
                  </p>
                  <a href="/"
                    className="card-button">Learn More</a>

                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-content">
              <img src={bb5} alt="Third Solution" className="carousel-image" />
              <div className="carousel-text">
                <div className="solution-card">
                  <h3 className="card-title">Faster Turnaround & Competitive Rates</h3>
                  <p className="card-text">
                    With an extensive database of pre-vetted consultants, we provide quick turnaround times for submissions.
                    Our competitive rate negotiation strategies ensure mutually beneficial contracts for both clients and consultants.
                  </p>
                  <a href="/"
                    className="card-button">Learn More</a>

                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-content">
              <img src={bb6} alt="Third Solution" className="carousel-image" />
              <div className="carousel-text">
                <div className="solution-card">
                  <h3 className="card-title">End-to-End Recruitment Solutions</h3>
                  <p className="card-text">
                    From initial candidate screening and marketing to client coordination and onboarding, we handle the entire recruitment lifecycle with precision and efficiency.
                    Our holistic approach ensures that both consultants and employers experience a seamless hiring process.
                  </p>
                  <a href="/"
                    className="card-button">Learn More</a>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        <br></br>
      </section>
      <section>
        <div className="container1 py-5 ">
          <div className="header-container">
            <div className="header-left">
              <h2 className="head">Carrier Launchers</h2>
              <h5 className="tit">We work with you to address your critical business priorities</h5>
            </div>
            <div className="header-right">
              <p className="desc">Our objective is to provide top-tier IT bench sales and recruitment solutions, connecting skilled IT professionals with leading organizations across the U.S. We aim to reduce bench time, streamline hiring, enhance workforce efficiency, and foster long-term partnerships while ensuring compliance, transparency, and career growth through data-driven, agile, and scalable staffing solutions.</p>
            </div>
          </div><br></br>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4">

            <div className="col">
              <div className="card h-100 shadow">
                <img src={H1} className="card-img-top" alt="Card Imag" />
                <div className="card-body text-center">
                  <h5 className="card-title1">IT Sourcing</h5>
                  <p className="card-text1">We specialize in IT sourcing, connecting businesses with top-tier IT professionals to meet their evolving technology needs. Our sourcing strategy involves identifying, engaging, and delivering highly skilled candidates for contract, full-time, and project-based roles across various industries.</p>
                  <Link to="/services/it-sourcing" className="card-button1">Learn More</Link>

                </div>
              </div>
            </div>

            <div className="col">
              <div className="card h-100 shadow">
                <img src={H2} className="card-img-top" alt="Card Imag" />
                <div className="card-body text-center">
                  <h5 className="card-title1">Consulting Services</h5>
                  <p className="card-text1">We offer expert IT consulting services to help businesses navigate the complexities of technology, optimize operations, and drive innovation. Our team of experienced consultants provides strategic guidance, tailored solutions, and hands-on expertise to ensure seamless digital transformation and workforce optimization.</p>
                  <Link to="/services/consulting-services" className="card-button1">Learn More</Link>

                </div>
              </div>
            </div>

            <div className="col">
              <div className="card h-100 shadow">
                <img src={H3} className="card-img-top" alt="Card Imag" />
                <div className="card-body text-center">
                  <h5 className="card-title1">IT Training</h5>
                  <p className="card-text1">At Carrier Launchers, we provide comprehensive IT training programs designed to equip professionals with the latest technical skills and industry knowledge. Our training solutions help businesses upskill their workforce and empower IT consultants with cutting-edge expertise to stay competitive in the ever-evolving technology landscape.</p>
                  <Link to="/services/it-training" className="card-button1">Learn More</Link>

                </div>
              </div>
            </div>

            <div className="col">
              <div className="card h-100 shadow">
                <img src={H4} className="card-img-top" alt="Card Imag" />
                <div className="card-body text-center">
                  <h5 className="card-title1">Business Process Services</h5>
                  <p className="card-text1">At Carrier Launchers, we offer Business Process Services (BPS) to help organizations streamline operations, enhance efficiency, and reduce costs. Our end-to-end solutions leverage automation, analytics, and industry best practices to optimize recruitment, workforce management, and IT operations.</p>
                  <Link to="/services/business-process-services" className="card-button1">Learn More</Link>

                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="new-section bg-f2f2f2 py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mb-1 new-text-center">
              <h2 className="new-section-title"> Who
                <span className="new-text-danger"> We  </span>Serve
              </h2>
              <div
                className="new-underline d-block mx-auto mb-2"
                style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
              ></div>
              <p className="new-section-subtitle">
                Explore Industries We Work With
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mb-1 new-text-center">
              <div className="new-card">
                <img src={F} className="new-card-img-top" alt="Service" />
                <div className="new-card-body new-text-center">
                  <h5 className="new-card-title">Banking & Financial Services</h5>
                  <div
                    className="new-underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="new-card-text">
                  Carrier Launchers provides top-tier IT staffing and consulting for banking and financial services, specializing in fintech, cybersecurity, AI, cloud solutions, compliance, and digital transformation. We connect financial institutions with skilled IT professionals, ensuring secure, efficient, and innovative solutions that drive operational excellence, regulatory adherence, and technological advancements in the financial sector.
                  </p>
                  <Link to="/industries/banking" className="new-readmore1">read more --</Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb-1 new-text-center">
              <div className="new-card">
                <img src={M} className="new-card-img-top" alt="Service" />
                <div className="new-card-body new-text-center">
                  <h5 className="new-card-title">Media & Technology</h5>
                  <div
                    className="new-underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="new-card-text">
                  Carrier Launchers delivers expert IT staffing and consulting solutions for the media and technology industry, specializing in AI, cloud computing, big data, cybersecurity, and digital transformation. We connect top IT talent with leading media firms, ensuring seamless content delivery, enhanced security, and cutting-edge innovations in entertainment, broadcasting, and digital platforms.
                  </p>
                  <Link to="/industries/media" className="new-readmore1">read more --</Link>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mb-1 new-text-center">
              <div className="new-card">
                <img src={I} className="new-card-img-top" alt="Service" />
                <div className="new-card-body new-text-center">
                  <h5 className="new-card-title">Insurance</h5>
                  <div
                    className="new-underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="new-card-text">
                  Carrier Launchers provides specialized IT staffing and consulting solutions for the insurance industry, focusing on digital transformation, AI-driven risk assessment, cybersecurity, cloud computing, and regulatory compliance. We help insurance firms enhance operational efficiency, data security, and customer experience by connecting them with top IT professionals skilled in modern insurance technologies and automation.
                  </p>
                  <Link to="/industries/issurance" className="new-readmore1">read more --</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-1 new-text-center">
              <div className="new-card">
                <img src={MA} className="new-card-img-top" alt="Service" />
                <div className="new-card-body new-text-center">
                  <h5 className="new-card-title">Manufacturing</h5>
                  <div
                    className="new-underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="new-card-text">
                  Carrier Launchers delivers IT staffing and consulting solutions for the manufacturing industry, specializing in automation, IoT, AI-driven analytics, ERP solutions, cloud computing, and cybersecurity. We help manufacturers optimize supply chains, production efficiency, and digital transformation by providing top-tier IT professionals who drive innovation, improve operations, and ensure seamless technology integration.
                  </p>
                  <Link to="/industries/manufacturing" className="new-readmore1">read more --</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-1 new-text-center">
              <div className="new-card">
                <img src={E} className="new-card-img-top" alt="Service" />
                <div className="new-card-body new-text-center">
                  <h5 className="new-card-title">Retail & E-commerce</h5>
                  <div
                    className="new-underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="new-card-text">
                  Carrier Launchers provides IT staffing and consulting solutions for the retail & eCommerce industry, specializing in omnichannel platforms, AI-driven analytics, cloud solutions, cybersecurity, and payment integrations. We help businesses enhance customer experience, supply chain efficiency, and digital transformation by delivering skilled IT professionals who drive innovation and optimize technology for seamless operations.
                  </p>
                  <Link to="/industries/retail" className="new-readmore1">read more --</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-1 new-text-center">
              <div className="new-card">
                <img src={HC} className="new-card-img-top" alt="Service" />
                <div className="new-card-body new-text-center">
                  <h5 className="new-card-title">Life Sciences & Healthcare</h5>
                  <div
                    className="new-underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="new-card-text">
                  Carrier Launchers specializes in IT staffing and consulting for the Life Sciences & Healthcare industry, providing expertise in EHR/EMR systems, AI-driven diagnostics, telemedicine, cloud computing, cybersecurity, and compliance solutions. We connect organizations with top IT talent to enhance patient care, streamline operations, and ensure regulatory compliance, driving digital transformation in healthcare.
                  </p>
                  <Link to="/industries/healthcare" className="new-readmore1">read more --</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-1 new-text-center">
              <div className="new-card">
                <img src={P} className="new-card-img-top" alt="Service" />
                <div className="new-card-body new-text-center">
                  <h5 className="new-card-title">Public Services</h5>
                  <div
                    className="new-underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="new-card-text">
                  Carrier Launchers delivers IT staffing and consulting solutions for the Public Services sector, helping government agencies, non-profits, and public institutions leverage cloud computing, cybersecurity, data analytics, and AI-driven automation. Our expert recruitment services ensure seamless digital transformation, operational efficiency, and regulatory compliance, empowering organizations to serve communities more effectively and securely.
                  </p>
                  <Link to="/industries/public-services" className="new-readmore1">read more --</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-1 new-text-center">
              <div className="new-card">
                <img src={A} className="new-card-img-top" alt="Service" />
                <div className="new-card-body new-text-center">
                  <h5 className="new-card-title">Aerospace</h5>
                  <div
                    className="new-underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="new-card-text">
                  Carrier Launchers provides IT staffing and consulting solutions for the Aerospace industry, specializing in AI-driven automation, cybersecurity, cloud computing, and data analytics. We help aerospace companies enhance safety, efficiency, and innovation by sourcing top IT talent for aircraft design, defense systems, supply chain optimization, and regulatory compliance, ensuring seamless digital transformation.
                  </p>
                  <Link to="/industries/aerospace" className="new-readmore1">read more --</Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-1 new-text-center">
              <div className="new-card">
                <img src={T} className="new-card-img-top" alt="Service" />
                <div className="new-card-body new-text-center">
                  <h5 className="new-card-title">Telecommunications</h5>
                  <div
                    className="new-underline d-block mx-auto mb-2"
                    style={{ width: '170px', height: '4px', backgroundColor: '#e74c3c' }}
                  ></div>
                  <p className="new-card-text">
                  Carrier Launchers provides IT staffing and consulting services for the Telecommunications industry, specializing in 5G technology, cloud computing, cybersecurity, AI-driven automation, and network infrastructure. We help telecom companies enhance connectivity, security, and digital transformation by sourcing top IT talent for network optimization, data analytics, IoT integration, and compliance with industry regulations to drive innovation.
                  </p>
                  <Link to="/industries/telecommunications" className="new-readmore1">read more --</Link>
                </div>
              </div>
            </div>
            {/* Add remaining services following the same structure */}
          </div>
        </div>
      </section>
      <section>
        <div className="clients-carousel-container">
          <h2 className="text-center">Our Clients</h2>
          <br></br>
          <div className="carousel-wrapper">
            <div className="scrolling-wrapper">
              {clients.map((client, index) => (
                <div className="logo-container" key={index}>
                  <img src={client.logo} alt={client.name} className="client-logo" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <br></br>
      <hr/>
      <section>
        <div className="col-md-12 mb-1 text-center">
          <h2 className="section-title">Our
            <span className="text-danger"> Memories </span>, Our Journey
          </h2>
          <div
            className="underline d-block mx-auto mb-2"
            style={{ width: '150px', height: '4px', backgroundColor: '#e74c3c' }}
          ></div>
          <br></br>
        </div>
        <div className="gallery-container">
          <div className="gallery-row">
            {images.map((image) => (
              <div key={image.id} className="gallery-column">
                <div className="gallery-image-card">
                  <img src={image.src} alt={image.alt} className="gallery-image" />
                  <div className="image-caption">
                    <h5 className="image-alt">{image.alt}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>



    </>
  );
};

export default Home;