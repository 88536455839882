import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import "../../../assets/css/Healthcare.css";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";

const Healthcare = ({ title = "Connecting Life Science & healthcare Businesses with Expert IT Professionals" }) => {

  const industries = [
    {
      title: "Pharmaceutical & Biotech",
      content: "The pharmaceutical and biotechnology sectors rely on cutting-edge IT solutions to support research, drug discovery, and regulatory compliance.",
      keyPoints: [
        "AI & Big Data Analytics – Accelerating drug discovery and clinical trials.",
        "Regulatory Compliance (FDA, HIPAA, GMP) – Ensuring adherence to industry standards.",
        "Cloud-Based R&D Platforms – Enhancing collaboration and data security.",
        "Clinical Trial & Drug Safety Software – Supporting precision medicine and patient safety."
      ]
    },
    {
      title: "Healthcare Providers & Hospitals",
      content: "With digital health solutions transforming patient care, healthcare organizations require specialized IT professionals for:",
      keyPoints: [
        "Electronic Health Records (EHR/EMR) Implementation – Supporting platforms like Epic and Cerner.",
        "Telemedicine & Remote Monitoring – Enabling virtual healthcare solutions.",
        "AI-Powered Diagnostics & Decision Support – Improving disease detection and treatment.",
        "Healthcare Cybersecurity & Data Privacy – Protecting patient data from breaches."
      ]
    },
    {
      title: "Medical Devices & IoT in Healthcare",
      content: "The integration of IoT, AI, and automation in medical devices is revolutionizing patient care and operational efficiency.",
      keyPoints: [
        "Smart Medical Device Development – Supporting IoT-enabled health monitoring solutions.",
        "Wearable Health Technologies – Enabling real-time patient tracking and diagnostics.",
        "Cloud & Edge Computing for Healthcare – Enhancing data storage and processing.",
        "AI-Based Predictive Maintenance – Ensuring device reliability and compliance."
      ]
    },
    {
      title: "Health Insurance & Payer Systems",
      content: "The health insurance industry requires secure, efficient, and scalable IT solutions to improve claim processing, fraud detection, and customer service.",
      keyPoints: [
        "AI & Machine Learning for Risk Analysis – Enhancing fraud detection and underwriting.",
        "Claims Processing & Automation – Reducing manual errors and operational costs.",
        "Blockchain for Secure Transactions – Ensuring data transparency and integrity.",
        "Compliance with ACA, HIPAA, and GDPR – Meeting legal and regulatory requirements."
      ]
    },
    {
      title: "Life Sciences Research & Genomics",
      content: "Advancements in genomic sequencing, bioinformatics, and AI-driven medical research require specialized IT expertise.",
      keyPoints: [
        "AI & Big Data for Genetic Research – Enabling personalized medicine and gene therapy.",
        "High-Performance Computing (HPC) for Bioinformatics – Powering advanced simulations and research.",
        "Cloud-Based Research Platforms – Enhancing collaboration among global research teams.",
        "Blockchain for Data Security – Ensuring integrity and confidentiality in life sciences research."
      ]
    }
  ];
 const { pathname } = useLocation(); // Get the current route

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the route changes
  }, [pathname]);

  return (
    <>
      <section className="health-container">
        <div className="health-bg-image">
          <h2 className="health-text animated-health-text">
            {title}
            <span className="health-underline"></span> {/* Underline Effect */}
          </h2>
        </div>
      </section>
      <section>
        <Container className="recruitment-container">
          <Row className="centered-row text-center">
            <Col md={10} lg={8}>
              <h1 className="recruitment-title">Top-Tier IT Bench Sales Recruitment for Life Sciences & Healthcare</h1>
              <p className="recruitment-subtitle">
                At <span className="highlight-text">Carrier Launchers</span>, we specialize in IT Bench Sales Recruitment Services for the Life Sciences & Healthcare industry...
              </p>
            </Col>
          </Row>

          <Row className="section-row">
            <Col>
              <Card className="info-card">
                <Card.Body>
                  <Card.Title className="info-card-title">Customized IT Staffing Solutions for Healthcare & Life Sciences</Card.Title>
                  <Card.Text>
                    We offer end-to-end recruitment services, including candidate sourcing, resume marketing, vendor management, contract negotiation, and onboarding support.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="section-row">
            <Col>
              <Card className="info-card">
                <Card.Body>
                  <Card.Title className="info-card-title">Expert IT Talent for Digital Healthcare Transformation</Card.Title>
                  <ListGroup variant="flush" className="service-list">
                    <ListGroup.Item>✅ <strong>EHR & EMR System Development</strong> – Hiring professionals skilled in Epic, Cerner, and custom-built platforms.</ListGroup.Item>
                    <ListGroup.Item>✅ <strong>Telemedicine & Remote Patient Monitoring</strong> – Supporting virtual healthcare solutions with AI-driven diagnostics.</ListGroup.Item>
                    <ListGroup.Item>✅ <strong>AI & Big Data in Healthcare</strong> – Enabling predictive analytics for disease prevention and personalized treatment.</ListGroup.Item>
                    <ListGroup.Item>✅ <strong>Pharmaceutical R&D & Clinical Trial Solutions</strong> – Recruiting IT talent for FDA-regulated research and drug development.</ListGroup.Item>
                    <ListGroup.Item>✅ <strong>Healthcare Cybersecurity & Compliance</strong> – Ensuring patient data protection and regulatory adherence.</ListGroup.Item>
                    <ListGroup.Item>✅ <strong>IoT & Wearable Health Technology</strong> – Developing smart medical devices and patient monitoring solutions.</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>

        </Container>
      </section>
      <section>
        <div className="life-header-section">
          <Container>
            <Row className="life-justify-content-center">
              <Col md={8} className="life-text-center">
                <h1>Industries We Serve: Life Sciences & Healthcare</h1>
                <p>
                  At Carrier Launchers, we specialize in IT Bench Sales Recruitment Services for the Life Sciences & Healthcare industry, helping businesses embrace digital transformation, enhance patient care, and improve operational efficiency.
                </p>
              </Col>
            </Row>
          </Container>

          <Container className="life-card-container">
            <Row>
              {industries.map((industry, index) => (
                <Col key={index} md={4} className="life-mb-4">
                  <Card className="life-h-100">
                    <Card.Body>
                      <Card.Title className="life-card-title">{industry.title}</Card.Title>
                      <Card.Text className="life-card-text">{industry.content}</Card.Text>
                      <ul className="life-ul">
                        {industry.keyPoints.map((point, i) => (
                          <li key={i} className="life-li">{point}</li>
                        ))}
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default Healthcare
