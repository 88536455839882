import React, { useState } from 'react';
import "../../assets/css/FAQ.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle the selected FAQ
  };

  const faqs = [
    {
      question: "How does Carrier Launchers help IT consultants find job opportunities?",
      answer:
        "We actively market bench candidates to our network of direct clients, Tier-1 vendors, and system integrators. Our team works on resume branding, job matching, interview scheduling, and negotiation to ensure quick placements in top IT projects.",
    },
    {
      question: "What technologies and domains do you specialize in?",
      answer:
        "We place IT professionals in various domains, including Cloud Computing, AI & Machine Learning, Cybersecurity, Data Analytics, DevOps, Software Development, ERP (SAP/Oracle), Networking, and Infrastructure Management.",
    },
    {
      question: "Do you provide sponsorship for work authorization?",
      answer:
        "Yes, we assist with H1B sponsorship, GC processing, and visa transfers, ensuring compliance with immigration regulations. Our legal team guides candidates through the entire process.",
    },
    {
      question: "How long does it take to get placed after joining your bench?",
      answer:
        "Placement timelines vary based on market demand, candidate skillset, and job availability. However, our strong vendor relationships and proactive marketing often lead to placements within 2 to 4 weeks.",
    },
    {
      question: "Do you offer resume and interview preparation support?",
      answer:
        "Absolutely! We provide resume optimization, LinkedIn profile enhancement, mock interviews, and technical training to help candidates present themselves effectively to clients.",
    },
    {
      question: "What kind of clients do you work with?",
      answer:
        "We collaborate with Fortune 500 companies, financial institutions, healthcare organizations, technology firms, and government agencies, ensuring high-quality and long-term projects for IT professionals.",
    },
    {
      question: "Do you provide training and upskilling programs?",
      answer:
        "Yes, we offer technical training, certification programs, and skill development courses to help consultants stay updated with emerging technologies and enhance their marketability.",
    },
    {
      question: "What is your payment structure and benefits package?",
      answer:
        "We offer competitive pay rates, direct deposit, timely payments, referral bonuses, healthcare benefits, and 401(k) options based on project terms and consultant preferences.",
    },
    {
      question: "How do I stay updated on new job opportunities?",
      answer:
        "Our recruiters maintain regular communication with consultants via calls, emails, and job portals, ensuring they are informed about new openings matching their expertise.",
    },
    {
      question: "How do I get started with Carrier Launchers?",
      answer:
        "Simply submit your resume through our website, connect with our recruiters, and we will guide you through profile marketing, job matching, and placement process for your next IT opportunity.",
    },
    {
      question: "Can I apply for multiple job roles?",
      answer:
        "Yes, we encourage candidates to explore multiple roles that align with their skill set. Our team will help you target the best opportunities.",
    },
    {
      question: "Do you work with W2, C2C, and 1099 consultants?",
      answer:
        "Yes, we provide staffing solutions for W2 (full-time employment), Corp-to-Corp (C2C) contractors, and 1099 independent consultants, depending on client requirements.",
    },
    {
      question: "What happens if a contract ends?",
      answer:
        "We proactively market your profile before your current contract ends, ensuring minimal downtime and a smooth transition to the next opportunity.",
    },
    {
      question: "How do I communicate my job preferences?",
      answer:
        "Our team conducts one-on-one discussions to understand your job preferences, including location, remote options, project type, rate expectations, and industry focus.",
    },
    {
      question: "Will my personal information remain confidential?",
      answer:
        "Yes, we maintain strict confidentiality and data security protocols, ensuring that your resume and personal details are shared only with potential employers.",
    },
    {
      question: "Can I negotiate my salary or hourly rate?",
      answer:
        "Absolutely! We work closely with clients to negotiate competitive pay rates based on your experience, skill set, and industry demand.",
    },
    {
      question: " Do you assist with client interviews?",
      answer:
        "Yes, we provide interview coaching, technical mock interviews, and real-time feedback to help you succeed in client interviews.",
    },
    {
      question: " Can I work remotely?",
      answer:
        "Yes, we have a growing number of remote and hybrid job opportunities, depending on client requirements and project scope.",
    },
    {
      question: " Do you offer referral bonuses?",
      answer:
        "Yes! We offer attractive referral bonuses for recommending skilled IT professionals who successfully get placed through our services.",
    },
    {
      question: " What makes Carrier Launchers different from other bench sales recruiters?",
      answer:
        "We provide a personalized, results-driven approach, leveraging strong client relationships, aggressive marketing, compliance expertise, and fast-tracked placement processes to ensure success for every consultant.",
    },
  ];

  const faqs1 = [
    {
      question: "What IT staffing services does Carrier Launchers provide?",
      answer:
        "We offer contract, contract-to-hire, and full-time IT staffing solutions for direct clients and Tier-1 vendors, specializing in banking, finance, healthcare, retail, telecommunications, and emerging technologies.",
    },
    {
      question: "What industries do you serve?",
      answer:
        "We cater to a wide range of industries, including Banking & Financial Services, Healthcare & Life Sciences, Retail & E-commerce, Manufacturing, Media & Technology, Public Sector, Aerospace, and Telecommunications.",
    },
    {
      question: "How do you ensure the quality of IT consultants?",
      answer:
        "Our consultants go through a rigorous screening process, including technical interviews, background verification, and skill assessments to ensure they meet client expectations.",
    },
    {
      question: "Do you provide IT consultants for short-term and long-term projects?",
      answer:
        "Yes, we provide skilled IT professionals for both short-term and long-term engagements, ensuring flexibility based on project requirements.",
    },
    {
      question: "What types of IT roles do you specialize in?",
      answer:
        "We specialize in Cloud Computing, AI/ML, Cybersecurity, Data Engineering, DevOps, Full-Stack Development, ERP (SAP/Oracle), Infrastructure Management, and Emerging Tech roles.",
    },
    {
      question: "How quickly can you provide IT consultants for our projects?",
      answer:
        "With a vast talent pool and an active bench, we can supply qualified candidates within 24–72 hours, depending on role complexity and client needs.",
    },
    {
      question: "What engagement models do you offer?",
      answer:
        "We offer Staff Augmentation, Direct Hiring, Contract-to-Hire, and Managed IT Services, tailored to our clients’ workforce and project demands.",
    },
    {
      question: "Can you provide consultants with specific compliance requirements?",
      answer:
        "Yes, we ensure consultants meet industry compliance standards, including HIPAA, GDPR, PCI-DSS, SOX, and ISO 27001, as per project needs.",
    },
    {
      question: "Do you handle all onboarding and paperwork?",
      answer:
        "Yes, we manage background checks, employment verification, payroll processing, tax compliance, and visa sponsorships, ensuring a smooth onboarding experience.",
    },
    {
      question: "How do you ensure retention and consultant satisfaction?",
      answer:
        "We prioritize competitive compensation, career growth, regular check-ins, and performance feedback to maintain high retention rates.",
    },
    {
      question: "Do you offer offshore or nearshore IT staffing solutions?",
      answer:
        "Yes, we provide onshore, offshore, and hybrid IT staffing solutions based on project requirements and cost optimization needs.",
    },
    {
      question: "How do you handle contract negotiations?",
      answer:
        "Our team streamlines contract negotiations, ensuring mutually beneficial terms for clients, vendors, and consultants while adhering to compliance standards.",
    },
    {
      question: "Do you support diversity and inclusion in hiring?",
      answer:
        "Yes, we actively promote diversity and inclusion (D&I) in IT hiring, ensuring equal opportunities across various talent pools.",
    },
    {
      question: "What is your pricing model for IT staffing?",
      answer:
        "We offer competitive and flexible pricing models, including hourly rates, fixed contracts, and milestone-based payments, tailored to client budgets and project needs.",
    },

    {
      question: "How do we get started with Carrier Launchers?",
      answer:
        "Simply reach out to our team, share your hiring requirements, and we will quickly provide pre-vetted candidates for your review and selection.",
    },
  ];

  return (
    <>
      {/* FAQ Header Section */}
      <section className="faq-header">
        <div className="faq-header-content">
          <h1>Frequently Asked Questions</h1>
        </div>
      </section>

      {/* Candidate-Specific FAQs */}
      <section className="scrollable-faq-section bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="section-title">
                Candidate-Specific <span className="text-danger">FAQs</span> (For IT Consultants/Bench Candidates)
              </h2>
              <div className="underline"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="faq-scroll-box">
                {faqs.map((faq, index) => (
                  <div key={index} className="faq-item mb-3">
                    <div
                      className="faq-question d-flex justify-content-between align-items-center bg-white p-3 border rounded"
                      onClick={() => toggleFAQ(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <h6 className="mb-0">{faq.question}</h6>
                      <span>
                        {activeIndex === index ? (
                          <i className="bi bi-dash-circle-fill text-danger"></i>
                        ) : (
                          <i className="bi bi-plus-circle-fill text-danger"></i>
                        )}
                      </span>
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer bg-light p-3 border rounded mt-2">
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Client/Vendor-Specific FAQs */}
      <section className="scrollable-faq-section bg-light py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="section-title">
                Client/Vendor-Specific <span className="text-danger">FAQs</span> (For Direct Clients & Tier-1 Vendors)
              </h2>
              <div className="underline"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="faq-scroll-box">
                {faqs1.map((faq, index) => (
                  <div key={index} className="faq-item mb-3">
                    <div
                      className="faq-question d-flex justify-content-between align-items-center bg-white p-3 border rounded"
                      onClick={() => toggleFAQ(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <h6 className="mb-0">{faq.question}</h6>
                      <span>
                        {activeIndex === index ? (
                          <i className="bi bi-dash-circle-fill text-danger"></i>
                        ) : (
                          <i className="bi bi-plus-circle-fill text-danger"></i>
                        )}
                      </span>
                    </div>
                    {activeIndex === index && (
                      <div className="faq-answer bg-light p-3 border rounded mt-2">
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;