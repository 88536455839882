import React, { useEffect, useState } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import "../assets/css/Navbar.css";
import Logo from "../assets/Images/Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";

// Dynamic Data Configuration
const navbarConfig = {
  logo: {
    src: Logo,
    alt: "Company Logo",
    width: 120,
    height: 60,
    link: "/home",
  },
  navLinks: [
    { title: "HOME", link: "/" },
    { title: "ABOUT US", link: "/about" },
    {
      title: "SERVICES",
      isDropdown: true,
      items: [
        { title: "IT Sourcing", link: "/services/it-sourcing" },
        { title: "Consulting Services", link: "/services/consulting-services" },
        { title: "IT Training", link: "/services/it-training" },
        { title: "Business Process Services", link: "/services/business-process-services" },
      ],
    },
    {
      title: "INDUSTRIES",
      isDropdown: true,
      items: [
        { title: "Finance & Banking", link: "/industries/banking" },
        { title: "Media & Technology", link: "/industries/media" },
        { title: "Issurance", link: "/industries/issurance" },
        { title: "Manufacturing", link: "/industries/manufacturing" },
        { title: "Retail & E-commerce", link: "/industries/retail" },
        { title: "Life Sciences & Healthcare", link: "/industries/healthcare" },
        { title: "Public Services", link: "/industries/public-services" },
        { title: "Aerospace", link: "/industries/aerospace" },
        { title: "Telecommunications", link: "/industries/telecommunications" },
      ],
    },
    { title: "FAQ", link: "/faq" },
    { title: "CONTACT US", link: "/contact" },
  ],
  socialMedia: [
    { icon: faFacebook, link: "https://www.facebook.com/profile.php?id=61557244721671" },
    { icon: faTwitter, link: "https://twitter.com" },
    { icon: faLinkedin, link: "https://www.linkedin.com/company/102868195" },
    { icon: faYoutube, link: "https://youtube.com" },
  ],
};

const CustomNavbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Navbar expand="lg" fixed="top" className={`custom-navbar ${scrolled ? "scrolled" : ""}`}>
      <Container>
        {/* Logo on the Left */}
        <Navbar.Brand href={navbarConfig.logo.link}>
          <img
            src={navbarConfig.logo.src}
            width={navbarConfig.logo.width}
            height={navbarConfig.logo.height}
            className="d-inline-block align-top logo"
            alt={navbarConfig.logo.alt}
          />
        </Navbar.Brand>

        {/* Toggle Button for Mobile */}
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <FontAwesomeIcon icon={faBars} />
        </Navbar.Toggle>

        {/* Navbar Content */}
        <Navbar.Collapse id="basic-navbar-nav">
          {/* Center Navigation Links */}
          <Nav className="mx-auto">
            {navbarConfig.navLinks.map((item, index) =>
              item.isDropdown ? (
                <NavDropdown
                  key={index}
                  title={item.title}
                  id={`${item.title.toLowerCase()}-dropdown`}
                  className="custom-dropdown"
                >
                  {item.items.map((dropdownItem, idx) => (
                    <NavDropdown.Item key={idx} href={dropdownItem.link}>
                      {dropdownItem.title}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ) : (
                <Nav.Link key={index} href={item.link}>
                  {item.title}
                </Nav.Link>
              )
            )}
          </Nav>

          {/* Right Side Social Media Icons */}
          <Nav className="ms-auto">
            {navbarConfig.socialMedia.map((social, index) => (
              <Nav.Link key={index} href={social.link} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={social.icon} />
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;